/* eslint-disable react/forbid-prop-types */
import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';

const styles = theme => ({
    root: {
        height: 'auto',
        display: 'block',
        backgroundColor: '#f3f2ec',
        overscrollBehaviorX: 'contain',
        maxWidth: '100%',
        overflowX: 'hidden',
    },
    title: {
        padding: '7px 0 7px 20px',
        borderLeft: 'solid 3px #922d38',
        [theme.breakpoints.down('sm')]: {
            padding: '6px 0 6px 16px',
        },
    },
    map: {
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
    media: {
        display: 'block',
        width: '100%',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            // height: 400,
            // minHeight: 400,
        },
    },
    action: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    button: {
        width: 300,
        padding: 20,
        border: 'none',
        borderRadius: 0,
        color: '#fff',
        margin: '0 24px',
        '&:hover': {
            border: 'none',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            border: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            padding: 14,
            width: '100%',
            margin: '0 0 16px 0',
        },
    },
    active: {
        color: '#fff',
        backgroundColor: '#cfae89',
    },
});

function ContactUs(props) {
    const { classes, data } = props;
    // console.log(data);

    return (
        <div className={classes.root} id="location">
            <Box pb={{ xs: 6, md: 10 }} pt={{ xs: 5, md: 10 }}>
                <Container>
                    <Grid spacing={3} container>
                        <Grid item xs={12}>
                            <Box pb={{ xs: '32px', md: '18px' }}>
                                <Box className={classes.title}>
                                    <Typography variant="h2" color="secondary">
                                        LOCATIONS
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        spacing={3}
                        container
                        direction="row"
                        justify="center"
                        alignItems="stretch">
                        <Grid item xs={12} md={8}>
                            <Box className={classes.map}>
                                {data.image && (
                                    <CardMedia
                                        component="img"
                                        className={classes.media}
                                        src={data.image}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        spacing={3}
                        container
                        direction="row"
                        justify="center"
                        alignItems="stretch">
                        <Grid item xs={12}>
                            <Box
                                className={classes.action}
                                mt={{ xs: '20px', md: 4 }}>
                                <Button
                                    className={classes.button}
                                    color="secondary"
                                    variant="contained"
                                    href={data.projectLocation}
                                    target="_blank">
                                    <LocationOnOutlinedIcon /> PROJECT LOCATION
                                </Button>
                                <Button
                                    className={classes.button}
                                    color="secondary"
                                    variant="contained"
                                    href={data.salesGallery}
                                    target="_blank">
                                    <LocationOnOutlinedIcon /> SALES GALLERY
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </div>
    );
}

ContactUs.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactUs);
