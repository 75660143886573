/* eslint-disable react/forbid-prop-types */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';
import Top from './top.svg';
import Bottom from './bottom.svg';
import MobileBottom from './mobileBottom.svg';
import MobileTop from './mobileTop.svg';

const styles = theme => ({
    outer: {
        overscrollBehaviorX: 'contain',
        maxWidth: '100%',
        overflowX: 'hidden',
    },
    root: {
        backgroundColor: '#f3f2ec',
        height: 'auto',
        display: 'block',
        paddingTop: 64,
        paddingBottom: 36,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 40,
            paddingBottom: 0,
        },
    },
    media: {
        position: 'relative',
        height: 928,
        [theme.breakpoints.down('sm')]: {
            height: 480,
        },
    },
    top: {
        top: '-1px',
        right: '-1px',
        position: 'absolute',
        [theme.breakpoints.down('sm')]: {
            top: '-1px',
            right: 0,
            left: 0,
            width: '100%',
        },
    },
    bottom: {
        bottom: '-1px',
        right: '-1px',
        position: 'absolute',
        [theme.breakpoints.down('sm')]: {
            bottom: '-1px',
            right: 0,
            left: 0,
            width: '100%',
        },
    },
    right: {
        display: 'flex',
        marginLeft: 120,
        maxWidth: 450,
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 16,
            marginRight: 16,
            height: 'auto',
        },
    },
    title: {
        padding: '8px 0 8px 20px',
        borderLeft: 'solid 3px #922d38',
        [theme.breakpoints.down('sm')]: {
            padding: '8px 0 8px 16px',
        },
    },
    list: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 18,
        },
    },
    head: {
        minWidth: 180,
        [theme.breakpoints.down('sm')]: {
            minWidth: 120,
        },
    },
    desc: {
        lineHeight: 1,
    },
    button: {
        height: 64,
        borderRadius: 0,
        maxWidth: 415,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            height: 48,
            maxWidth: '100%',
            marginBottom: 48,
        },
    },
});

function Download(props) {
    const { classes, data } = props;

    return (
        <div className={classes.outer}>
            <div className={classes.root} id="info">
                <Grid
                    spacing={0}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center">
                    <Hidden smDown>
                        <Grid item xs={12} md={6}>
                            {data.img && (
                                <CardMedia
                                    component="div"
                                    alt="Holme Ekkamai 22"
                                    className={classes.media}
                                    image={data.img}
                                    title="Holme Ekkamai 22">
                                    <img
                                        src={Top}
                                        className={classes.top}
                                        alt="project info"
                                    />
                                    <img
                                        src={Bottom}
                                        className={classes.bottom}
                                        alt="project info"
                                    />
                                    <Typography variant="caption" style={{
                                        color: '#fff',
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        margin: 8,
                                    }}>
                                        COMPUTER GENERATED
                                    </Typography>
                                </CardMedia>
                            )}
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={6}>
                        <Grid
                            spacing={0}
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start">
                            <Grid item xs={12} md={10}>
                                <Box className={classes.right}>
                                    <Box
                                        mb={{ xs: 2, md: 7 }}
                                        className={classes.title}>
                                        <Typography
                                            variant="h2"
                                            color="secondary">
                                            PROJECT INFO
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Box className={classes.list}>
                                            <Typography
                                                variant="button"
                                                color="secondary"
                                                component="p"
                                                className={classes.head}>
                                                ชื่อโครงการ
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                color="secondary"
                                                className={classes.desc}>
                                                {data.name}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.list}>
                                            <Typography
                                                variant="button"
                                                color="secondary"
                                                component="p"
                                                className={classes.head}>
                                                เจ้าของโครงการ
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                color="secondary"
                                                className={classes.desc}>
                                                {data.owner}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.list}>
                                            <Typography
                                                variant="button"
                                                color="secondary"
                                                component="p"
                                                className={classes.head}>
                                                ที่ตั้งโครงการ
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                color="secondary"
                                                className={classes.desc}>
                                                {data.address}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.list}>
                                            <Typography
                                                variant="button"
                                                color="secondary"
                                                component="p"
                                                className={classes.head}>
                                                พื้นที่โครงการ
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                color="secondary"
                                                className={classes.desc}>
                                                {data.area}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.list}>
                                            <Typography
                                                variant="button"
                                                color="secondary"
                                                component="p"
                                                className={classes.head}>
                                                ลักษณะโครงการ
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                color="secondary"
                                                className={classes.desc}>
                                                {data.character}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.list}>
                                            <Typography
                                                variant="button"
                                                color="secondary"
                                                component="p"
                                                className={classes.head}>
                                                จำนวนยูนิต
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                color="secondary"
                                                className={classes.desc}>
                                                {data.unit}
                                            </Typography>
                                        </Box>
                                        <Box mb={{ xs: 4, md: 6 }}>
                                            <Box mb={1}>
                                                <Typography
                                                    variant="button"
                                                    color="secondary"
                                                    component="p">
                                                    ประเภทห้อง
                                                </Typography>
                                            </Box>
                                            <Typography
                                                variant="body1"
                                                color="secondary"
                                                dangerouslySetInnerHTML={{
                                                    __html: data.roomType.replace(
                                                        /\n\r?/g,
                                                        '<br />',
                                                    ),
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <a
                                        href={data.file}
                                        download
                                        style={{ textDecoration: 'none' }}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <Button
                                            className={classes.button}
                                            variant="contained"
                                            color="secondary"
                                            // onClick={onClick}
                                        >
                                            Download E-brochure
                                        </Button>
                                    </a>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Hidden mdUp>
                {data.img && (
                    <CardMedia
                        component="div"
                        alt="Holme Ekkamai 22"
                        className={classes.media}
                        image={data.img}
                        title="Holme Ekkamai 22">
                        <Hidden mdUp>
                            <img
                                src={MobileTop}
                                className={classes.top}
                                alt="project info"
                            />
                            <img
                                src={MobileBottom}
                                className={classes.bottom}
                                alt="project info"
                            />
                        </Hidden>
                        <Hidden smDown>
                            <img
                                src={Top}
                                className={classes.top}
                                alt="project info"
                            />
                            <img
                                src={Bottom}
                                className={classes.bottom}
                                alt="project info"
                            />
                        </Hidden>
                    </CardMedia>
                )}
            </Hidden>
        </div>
    );
}

Download.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default withStyles(styles)(Download);
