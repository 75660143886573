import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
// import clsx from 'clsx';
// import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
// import CloseIcon from '@material-ui/icons/Close';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import ErrorIcon from '@material-ui/icons/Error';
import Dialog from '@material-ui/core/Dialog';
import Logo from 'common/icon/loading.svg';
import Navbar from './components/navbar';
// import FixFooter from './components/fixFooter';
import Footer from './components/footer';

const useStyles = makeStyles(theme => ({
    progress: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        margin: 'auto',
        width: 'auto',
        height: 110,
        [theme.breakpoints.down('sm')]: {
            height: 85,
        },
    },
    // Dialog
    dialogPaper: {
        backgroundColor: '#922d38',
    },
}));

// const useStyles = makeStyles(theme => ({
//     error: {
//         backgroundColor: "#de0032",
//     },
//     success: {
//         backgroundColor: "#00abbd",
//     },
//     icon: {
//         fontSize: 20,
//     },
//     iconVariant: {
//         opacity: 0.9,
//         marginRight: theme.spacing(1),
//     },
//     message: {
//         display: 'flex',
//         alignItems: 'center',
//     },
// }));

const Main = props => {
    const {
        children,
        lang,
        loading,
        // snackIsShow,
        // snackMessage,
        // snackStatus,
        // hideSnack,
    } = props;

    const classes = useStyles();

    // function handleClose() {
    //     hideSnack();
    // }

    // let snackContent = <div></div>;
    // if (snackStatus === 'success') {
    //     snackContent = <SnackbarContent
    //         className={classes.success}
    //         aria-describedby="client-snackbar"
    //         message={
    //             <span id="client-snackbar" className={classes.message}>
    //                 <CheckCircleIcon className={clsx(classes.icon, classes.iconVariant)} /> {snackMessage}
    //             </span>
    //         }
    //         action={[
    //             <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
    //                 <CloseIcon className={classes.icon} />
    //             </IconButton>,
    //         ]}
    //     />;
    // }
    // if (snackStatus === 'fail') {
    //     snackContent = <SnackbarContent
    //         className={classes.error}
    //         aria-describedby="client-snackbar"
    //         message={
    //             <span id="client-snackbar" className={classes.message}>
    //                 <ErrorIcon className={clsx(classes.icon, classes.iconVariant)} /> {snackMessage}
    //             </span>
    //         }
    //         action={[
    //             <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
    //                 <CloseIcon className={classes.icon} />
    //             </IconButton>,
    //         ]}
    //     />;
    // }

    return (
        <>
            {/* <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                open={snackIsShow}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                {snackContent}
            </Snackbar> */}
            <Dialog
                fullScreen
                open={loading}
                transitionDuration={{
                    enter: 0,
                    exit: 1000,
                }}
                classes={{
                    paper: classes.dialogPaper,
                }}>
                <img className={classes.progress} src={Logo} alt="Loading" />
            </Dialog>
            <Navbar bg="white" lang={lang} />
            {/* <FixFooter bg="white" lang={lang} /> */}
            {children}
            <Footer lang={lang} />
        </>
    );
};

Main.propTypes = {
    children: PropTypes.node.isRequired,
    lang: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    // snackIsShow: PropTypes.bool.isRequired,
    // snackMessage: PropTypes.string.isRequired,
    // snackStatus: PropTypes.string.isRequired,
    // hideSnack: PropTypes.func.isRequired,
};

Main.defaultProps = {
    loading: true,
};

export default Main;
