import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    spacing: 8,
    palette: {
        // Element & Text
        common: {
            black: "#000",
            white: "#fff"
        },
        background: {
            default: "#f4f4f4",
            paper: "#fff"
        },
        // Text
        primary: {
            // light: "rgba(120, 119, 115, 1)",
            main: "#82bad1",
            dark: "#57a8c9",
            contrastText: "#fff"
        },
        secondary: {
            // light: "rgba(217, 210, 200, 1)",
            main: "#922d38",
            dark: "#5c1b29",
            contrastText: "#fff"
        },
        error: {
            main: "#fff",
            contrastText: "#fff"
        },
        text: {
            primary: "#82bad1",
            secondary: "#922d38",
            disabled: "rgba(120, 119, 115, 1)",
            hint: "#ffffff"
        }
    },
    typography: {
        fontFamily: [
            '"DB Heavent"',
        ].join(','),
        h1:{
            fontSize: "72px",
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1,
            letterSpacing: 'normal',
            "@media screen and (max-width: 961px)": {
                fontSize: "56px",
            }
        },
        h2: {
            fontFamily: "D-DIN Exp",
            fontSize: "50px",
            fontWeight: "normal",
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1,
            letterSpacing: 2,
            "@media screen and (max-width: 961px)": {
                fontSize: "44px",
            }
        },
        h3: {
            fontSize: "32px",
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1,
            letterSpacing: 'normal',
            "@media screen and (max-width: 961px)": {
                fontSize: "28px",
            }
        },
        subtitle1:{
            fontSize: "28px",
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.29,
            letterSpacing: 'normal',
            "@media screen and (max-width: 961px)": {
                fontSize: "24px",
                lineHeight: 1.33,
            }
        },
        subtitle2: {
            fontFamily: "DB Heavent Med",
            fontSize: "22px",
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.36,
            letterSpacing: 'normal',
            "@media screen and (max-width: 961px)": {
                fontSize: "20px",
                lineHeight: 1.4,
            }
        },
        body1:{
            fontSize: "24px",
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.33,
            letterSpacing: 'normal',
            "@media screen and (max-width: 961px)": {
                fontSize: "22px",
                lineHeight: 1.36,
            }
        },
        body2:{
            fontSize: "20px",
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1,
            letterSpacing: 'normal',
            "@media screen and (max-width: 961px)": {
                fontSize: "18px",
            }
        },
        button:{
            fontSize: "24px",
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1,
            letterSpacing: 1,
            "@media screen and (max-width: 961px)": {
                fontSize: "20px",
            }
        },
        caption: {
            fontSize: "18px",
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1,
            letterSpacing: 'normal',
            "@media screen and (max-width: 961px)": {
                fontSize: "16px",
            }
        },
        overline: {
            fontSize: "20px",
            fontWeight: "normal",
            fontStyle: "normal",
            fontStretch: "normal",
            lineHeight: 1.4,
            letterSpacing: "normal",
            textTransform: 'none',
            "@media screen and (max-width: 961px)": {
                fontSize: "18px",
                lineHeight: 1.44,
            }
        },
    },
});

export default theme;