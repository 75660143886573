// import _ from 'lodash';
import firebase from 'common/firebase';

// Action
// -- CREATE/UPDATE
export const FETCH_HOME = 'FETCH_HOME';
export const FETCH_HOME_DONE = 'FETCH_HOME_DONE';
export const FETCH_HOME_FAIL = 'FETCH_HOME_FAIL';

// Sync Action Creator
export function fetch() {
    return {
        type: FETCH_HOME,
    };
}
export function fetchDone(data) {
    return {
        type: FETCH_HOME_DONE,
        data,
    };
}
export function fetchFail(message) {
    return {
        type: FETCH_HOME_FAIL,
        message,
    };
}

// Async Action Creator
export function getData() {
    return async dispatch => {
        dispatch(fetch());

        const ref = firebase
            .firestore()
            .collection('pages')
            .doc('home');
        try {
            const result = await ref.get();
            const homeData = result.data();

            // Gallery
            const galleryList = [];
            const galleryArr = homeData.register;
            galleryArr.forEach((item, index) => {
                galleryList.push({
                    order: index,
                    img: item,
                });
            });

            const output = {
                floorplan: homeData.floorplan,
                unitplan: homeData.unitplan,
                register: galleryList,
                info: homeData.info,
                gallery: homeData.gallery,
                video: homeData.video,
                progress: homeData.progress,
                contactUs: homeData.map,
            };
            dispatch(fetchDone(output));
        } catch (error) {
            dispatch(fetchFail(error.message));
        }
    };
}
