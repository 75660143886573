import {
    FETCH_ABOUT,
    FETCH_ABOUT_DONE,
    FETCH_ABOUT_FAIL,
} from 'actions/aboutus';

const initialState = {
    loading: true,
    data: {
        img: '',
        desc: '',
    },
    failMessage: '',
};

const aboutReducer = (state = initialState, action) => {
    switch (action.type) {
    case FETCH_ABOUT:
        return {
            ...state,
            loading: true,
        };
    case FETCH_ABOUT_FAIL:
        return {
            ...state,
            loading: true,
            failMessage: action.message,
        };
    case FETCH_ABOUT_DONE:
        return {
            ...state,
            loading: false,
            data: action.data,
        };
    default:
        return state;
    }
};
export default aboutReducer;
