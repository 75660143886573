import { FETCH_HOME, FETCH_HOME_DONE, FETCH_HOME_FAIL } from 'actions/home';

const initialState = {
    loading: true,
    data: {
        cover: {
            img: '',
        },
        register: [],
        projectConcept: {
            img: {
                one: '',
                two: '',
            },
            desc: '',
        },
        materials: {
            img: {
                one: '',
                two: '',
            },
            desc: '',
        },
        info: {
            img: '',
            name: '',
            owner: '',
            address: '',
            character: '',
            file: '',
            roomType: '',
            unit: '',
            area: '',
        },
        interior: [],
        floorplan: [],
        unitplan: [],
        gallery: [],
        neighbourGallery: [],
        video:{
            url:'',
            urlTwo:'',
            cover:'',
            coverTwo:'',
        },
        progress: {
            img: '',
            desc: '',
            allPercent: null,
            archPercent: null,
            strPercent: null,
            sysPercent: null,
        },
        contactUs: {
            image: '',
            projectLocation: '',
            salesGallery: '',
        },
    },
    failMessage: '',
};

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
    case FETCH_HOME:
        return {
            ...state,
            loading: true,
        };
    case FETCH_HOME_FAIL:
        return {
            ...state,
            loading: true,
            failMessage: action.message,
        };
    case FETCH_HOME_DONE:
        return {
            ...state,
            loading: false,
            data: action.data,
        };
    default:
        return state;
    }
};
export default homeReducer;
