/* eslint-disable react/forbid-prop-types */
import React from 'react';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import CloseIcon from '@material-ui/icons/Close';
import Logo from './video.svg';
import Top from './top.svg';
import Bottom from './bottom.svg';
import MobileBottom from './mobileBottom.svg';
import DesktopBottom from './desktopBottom.svg';

const styles = theme => ({
    root: {
        height: 'auto',
        display: 'block',
        backgroundColor: '#922d38',
        overscrollBehaviorX: 'contain',
        maxWidth: '100%',
        overflowX: 'hidden',
    },
    title: {
        padding: '7px 0 7px 20px',
        borderLeft: 'solid 3px #fff',
        [theme.breakpoints.down('sm')]: {
            padding: '6px 0 6px 16px',
        },
    },
    video: {
        position: 'relative',
        cursor: 'pointer',
        backgroundBlendMode: 'multiply',
        backgroundColor: 'transparent',
        transition: 'all 0.5s ease',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: '#1c1c1cd1',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 40,
        },
    },
    play: {
        width: 100,
        height: 100,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: 64,
            height: 64,
        },
    },
    top: {
        position: 'absolute',
        width: '100%',
        top: 0,
        left: 0,
        right: 0,
        transform: 'scaleY(-1)',
        transformOrigin: 'top left',
    },
    bottom: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
        left: 0,
        right: 0,
        transform: 'scaleY(-1)',
        transformOrigin: 'bottom left',
    },
    image: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
    },
    landscape: {
        height: 486,
        [theme.breakpoints.down('sm')]: {
            height: 246,
        },
    },
    dialogPaper: {
        overflowY: 'visible',
        backgroundColor: '#fff0',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 0,
        },
    },
    backdrop: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#000',
        },
    },
    closeBtn: {
        position: 'absolute',
        top: '-50px',
        right: 0,
        color: '#fff',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.16)',
        },
    },
    fullImg: {
        maxHeight: '80vh',
        width: 900,
        height: 507,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 250,
        },
    },
    rootTwo: {
        backgroundColor: '#f3f2ec',
        position: 'relative',
        '& img': {
            position: 'relative',
            width: '100%',
            // height: 155,
            top: '-1px',
            display: 'block',
            // position: 'absolute',
        },
    },
});

function ContactUs(props) {
    const { classes, data } = props;
    // console.log(data);

    const [number, setNumber] = React.useState(null);

    const [open, setOpen] = React.useState(false);
    function handleClickOpen(num) {
        setNumber(num);
        setOpen(true);
    }
    function handleClose() {
        setOpen(false);
    }

    return (
        <div className={classes.root} id="contactus">
            <Box pb={{ xs: 6, md: 13 }} pt={{ xs: 13, md: 8 }}>
                <Container>
                    <Grid spacing={6} container>
                        <Grid item xs={12}>
                            <Box pb={{ xs: 7, md: 11 }}>
                                <Box className={classes.title}>
                                    <Typography
                                        variant="h2"
                                        style={{ color: '#fff' }}>
                                        VIDEO
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        spacing={6}
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start">
                        {data.url && data.cover && (
                            <Grid item xs={12} md={6}>
                                <CardMedia
                                    component="div"
                                    alt="The Moment"
                                    className={clsx(
                                        classes.landscape,
                                        classes.video,
                                    )}
                                    image={data.cover}
                                    title="The Moment"
                                    onClick={() => handleClickOpen(0)}>
                                    <img
                                        src={Logo}
                                        className={classes.play}
                                        alt="The Moment"
                                    />
                                    <img
                                        src={Bottom}
                                        className={classes.top}
                                        alt="The Moment"
                                    />
                                    <img
                                        src={Top}
                                        className={classes.bottom}
                                        alt="The Moment"
                                    />
                                </CardMedia>
                            </Grid>
                        )}
                        {data.urlTwo && data.coverTwo && (
                            <Grid item xs={12} md={6}>
                                <CardMedia
                                    component="div"
                                    alt="The Moment"
                                    className={clsx(
                                        classes.landscape,
                                        classes.video,
                                    )}
                                    image={data.coverTwo}
                                    title="The Moment"
                                    onClick={() => handleClickOpen(1)}>
                                    <img
                                        src={Logo}
                                        className={classes.play}
                                        alt="The Moment"
                                    />
                                    <img
                                        src={Bottom}
                                        className={classes.top}
                                        alt="The Moment"
                                    />
                                    <img
                                        src={Top}
                                        className={classes.bottom}
                                        alt="The Moment"
                                    />
                                </CardMedia>
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </Box>
            {/* Dialog */}
            <Dialog
                maxWidth="md"
                open={open}
                onClose={handleClose}
                BackdropProps={{
                    classes: {
                        root: classes.backdrop,
                    },
                }}
                classes={{
                    paper: classes.dialogPaper,
                }}>
                <IconButton onClick={handleClose} className={classes.closeBtn}>
                    <CloseIcon />
                </IconButton>
                <Box style={{ position: 'relative' }}>
                    {number === 0 && (
                        <iframe
                            title="The Moment"
                            className={classes.fullImg}
                            src={data.url}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullscreen
                        />
                    )}
                    {number === 1 && (
                        <iframe
                            title="The Moment"
                            className={classes.fullImg}
                            // width="560"
                            // height="315"
                            src={data.urlTwo}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    )}
                </Box>
            </Dialog>
            <Hidden smDown>
                <div className={classes.rootTwo}>
                    <img src={DesktopBottom} alt="facility" />
                </div>
            </Hidden>
            <Hidden mdUp>
                <div className={classes.rootTwo}>
                    <img src={MobileBottom} alt="facility" />
                </div>
            </Hidden>
        </div>
    );
}

ContactUs.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactUs);
