import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import FullLogo from 'common/icon/logo-full.svg';
import Facebook from 'common/icon/facebook.svg';
import Line from 'common/icon/line.svg';
// import Instagram from 'common/icon/instagram.svg';
import CloseImage from 'common/icon/close.svg';

const styles = theme => ({
    footer: {
        position: 'relative',
        display: 'block',
        backgroundColor: '#202b4f',
    },
    // LeftSide
    logo: {
        height: 77,
    },
    // RightSide
    contact: {
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
    },
    contactList: {
        display: 'flex',
        flexDirection: 'row',
        '&& img': {
            marginRight: 12,
        },
        [theme.breakpoints.down('sm')]: {
            // textAlign: 'left',
        },
    },
    contactInner: {
        textAlign: 'left',
    },
    contactTitle: {
        color: '#fff',
        display: 'inline-block',
        marginRight: 8,
    },
    dialogPaper: {
        position: 'relative',
        backgroundColor: '#82bad1',
        borderRadius: 0,
        padding: '60px 48px',
        overflow: 'visible',
        maxHeight: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '24px 16px',
            margin: '0 24px',
        },
    },
    dialogContent: {
        height: '100%',
        overflowY: 'scroll',
    },
    closeBtn: {
        position: 'absolute',
        cursor: 'pointer',
        top: '-21px',
        right: '-21px',
        [theme.breakpoints.down('sm')]: {
            top: '-17px',
            right: '-17px',
        },
    },
    closeImg: {
        width: 42,
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: 34,
        },
    },
});

function Footer(props) {
    const { classes } = props;

    const [open, setOpen] = React.useState(false);
    function handleClickOpen() {
        setOpen(true);
    }
    function handleClose() {
        setOpen(false);
    }

    return (
        <div className={classes.footer}>
            <Container>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                    spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Box pt={{ xs: '38px', md: '28px' }}>
                            <img
                                className={classes.logo}
                                src={FullLogo}
                                alt="The Moment of Living"
                            />
                        </Box>
                        <Box pt={3}>
                            <Typography variant="body1" color="primary">
                                บริษัท โมเมนต์ ออฟ ลิฟวิ่ง แอสเสท จำกัด
                                <br />
                                41/4 ตรอกเวท แขวงสีลม เขตบางรัก กรุงเทพมหานคร
                                10500
                            </Typography>
                        </Box>
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={2} />
                    </Hidden>
                    <Grid item xs={12} md={6}>
                        <Box className={classes.contact}>
                            <Box
                                pt={{ xs: 0, md: '22px' }}
                                className={classes.contactInner}
                                display="inline-block">
                                <Box className={classes.contactList}>
                                    <img
                                        src={Line}
                                        alt="The Moment of Living"
                                    />
                                    <a
                                        href="https://lin.ee/wBWyRon"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <Typography
                                            variant="body1"
                                            style={{ display: 'inline-block' }}
                                            color="primary">
                                            @themomentcondo
                                        </Typography>
                                    </a>
                                </Box>
                                <Box className={classes.contactList}>
                                    <img
                                        src={Facebook}
                                        alt="The Moment of Living"
                                    />
                                    <a
                                        href="https://www.facebook.com/themomentcondo/"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <Typography
                                            variant="body1"
                                            style={{ display: 'inline-block' }}
                                            color="primary">
                                            The Moment Condo
                                        </Typography>
                                    </a>
                                </Box>
                                {/* <Box className={classes.contactList} >
                                    <img
                                        src={Instagram}
                                        alt="The Moment of Living"
                                    />
                                    <a href="https://www.instagram.com/themomentcondo" target="_blank" rel="noopener noreferrer">
                                        <Typography
                                            variant="body1"
                                            style={{ display: 'inline-block' }}
                                            color="primary"
                                        >
                                            The Moment Condo
                                        </Typography>
                                    </a>
                                </Box> */}
                                <Box pt={3}>
                                    <Typography variant="body2" color="primary">
                                        *เงื่อนไขเป็นไปตามที่บริษัทฯ
                                        กำหนดรายละเอียด
                                    </Typography>
                                    <Box>
                                        <Typography
                                            variant="body2"
                                            color="primary"
                                            style={{ display: 'inline-block' }}>
                                            โปรโมชันเพิ่มเติม
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="primary"
                                            style={{
                                                display: 'inline-block',
                                                textDecoration: 'underline',
                                                margin: '0 4px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleClickOpen}>
                                            คลิก
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Box pt={{ xs: 0, md: 1 }} pb={{ xs: '12px', md: 1 }}>
                            <Typography
                                variant="caption"
                                color="primary"
                                align="center">
                                The moment © 2020. All Rights Reserved.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            {/* Dialog */}
            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleClose}
                classes={{
                    paper: classes.dialogPaper,
                }}>
                <Box onClick={handleClose} className={classes.closeBtn}>
                    <img
                        src={CloseImage}
                        alt="Close Button"
                        className={classes.closeImg}
                    />
                </Box>
                <Box className={classes.dialogContent}>
                    <Typography variant="body2" style={{ color: '#fff' }}>
                        เจ้าของโครงการ  บริษัท โมเมนต์ ออฟ ลิฟวิ่ง แอสเสท จำกัด ทะเบียนเลขที่ 0105562120720 ที่ตั้ง 41/4  ตรอกเวท แขวงสีลม เขตบางรัก กรุงเทพมหานคร 10500 ทุนจดทะเบียน 5,000,000 บาท กรรมการผู้จัดการ นาย ณธเดช สุนทรปกาสิต ที่ตั้งโครงการอยู่ที่ ซอยวิภาวดี 16 ถนนวิภาวดี เขตดินแดง แขวงดินแดง กรุงเทพ โฉนดที่ดินเลขที่ 4239,43381,43387  เลขที่ดิน 382, 383, 380 เนื้อที่โครงการประมาณ 342 ตารางวา ประกอบไปด้วยอาคารชุดพักอาศัยสูง 8 ชั้น จำนวน 1 อาคาร จำนวนห้องชุดพักอาศัย 134 ห้อง เริ่มก่อสร้างโดยประมาณ เดือนพฤศจิกายน ปีพ.ศ. 2563 และคาดว่าจะแล้วเสร็จประมาณ เดือนกุมภาพันธ์ ปีพ.ศ. 2565 ภาพทั้งหมดเป็นภาพและบรรยากาศจำลอง บริษัทฯ ขอสงวนสิทธิ์ในการเปลี่ยนแปลงข้อมูลรายละเอียดและข้อความต่างๆ โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                        <br />
                        <br />
                        *ราคาเริ่มต้น 2 ล้านบาท จำนวน 1 ยูนิตเท่านั้น
                        <br />
                        <br />
                        **ส่วนลดสูงสุด 150,000 บาทจะได้รับ ณ วันโอนกรรมสิทธิ์ โดยที่ราคาพิเศษ 2 ล้านบาทเป็นราคาพิเศษไม่เข้าร่วมส่วนลดนี้ ***เมื่อจ่ายเงินจองและจ่ายค่าทำสัญญาภายในไม่เกิน 14 วันหลังจากวันจองจะได้รับเครื่องกรองอากาศ 1 เครื่องภายใน 60 วันหลังจากวันทำสัญญา 
                        <br />
                        <br />
                        ***Promotion exclusive deal มี 8 ยูนิตพิเศษเท่านั้นที่จะได้รับส่วนลด 200,000 บาทคือ ยูนิตเลขที่ 207,210,310,314,315,320,402 และ 501
                        <br />
                        <br />
                        ****ลูกค้าที่จองตั้งแต่วันที่ 1-31 พฤษภาคม 2563 ได้มีสิทธิ์จับฉลากเพื่อลุ้นรับสร้อยคอทองคำ 1 สลึง ( 1 ห้องต่อ 1 สิทธิ์) โดยลูกค้าจะได้รับภายใน 15 วัน หลังชำระงวดดาวน์ครบ 1 งวดไปแล้ว 
                        <br />
                        <br />
                        *****Promotion friend get friend มูลค่า 10,000 บาท แบ่งเป็น ผู้แนะนำจะได้ gift voucher / คูปองเงินสด มูลค่า 5,000 บาท   และ ผู้ถูกแนะนำ(ผู้ซื้อ) จะได้ส่วนลดวันโอนกรรมสิทธิ์เป็นจำนวนเงิน 5,000 บาท ซึ่งผู้แนะนำจะได้ gift voucher / คูปองเงินสด ภายใน 60 วัน หลังจากผู้ซื้อได้ผ่อนงวดดาวน์ครบ 4 งวดแล้ว
                        <br />
                        <br />
                        ******ส่วนลด Direct Sales หรือ ส่วนลด Corporate จะได้รับส่วนลด Ontop เพิ่ม แบบห้อง Studioและ1-Bed Room ลดเพิ่ม 20,0000 บาท และ 1-Bedroom Plus ลดเพิ่ม 30,000 บาท (ห้อง Exclusive Deal จะไม่ได้รับส่วนลดนี้)
                        <br />
                        <br />
                        เงื่อนไข / หลักเกณฑ์ / วิธีการ ลูกค้าจะต้องจองและทำสัญญาจะซื้อจะขายห้องชุดตั้งแต่วันที่ 1-31 พฤษภาคม 2563 โปรโมชั่นนี้ไม่สามารถแลกเปลี่ยนเป็นส่วนลดหรือเงินสดได้ บริษัทฯ ขอสงวนสิทธิ์ในการยกเลิกการให้โปรโมชั่น สำหรับลูกค้าที่ไม่ปฏิบัติตามข้อกำหนดและเงื่อนไขการชำระเงินที่บริษัทฯ กำหนด ให้ติดต่อสำนักงานขายประจำโครงการฯ  เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด ขอสงวนสิทธิ์ในการเปลี่ยนแปลงยกเลิกหรือแก้ไขโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                    </Typography>
                </Box>
            </Dialog>
        </div>
    );
}

Footer.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
