import React from 'react';
import { connect } from 'react-redux';
import { 
    Route, 
    Redirect,
    withRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';
// import ScrollToTop from 'react-router-scroll-top';
import {
    closeGetInTouch,
} from 'actions/register';
import {
    setLang,
} from 'actions/main';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles(() => ({
//     progress: {
//         position: 'absolute',
//         top: 0,
//         left: 0,
//         bottom: 0,
//         right: 0,
//         margin: 'auto',
//     },
// }));

const RouteWithLayout = props => {
    const {
        layout: Layout,
        component: Component,
        isRegistered,
        resetRegiter,
        location, 
        setLanguage,
        aboutLoading,
        progressLoading,
        homeLoading,
    } = props;

    const { pathname } = location;
    const urlParam = pathname.split("/");
    const lang = 'th';
    setLanguage(lang);
    
    let loading = true;
    if (urlParam[1] === 'progress' && !progressLoading){
        loading = false;
    } else if (urlParam[1] === 'aboutus' && !aboutLoading){
        loading = false;
    } else {
        loading = homeLoading;
    }

    let outputComponent = (
        // <ScrollToTop>
        <Layout lang={lang} loading={loading}>
            <Component />
        </Layout>
        // </ScrollToTop>
    );

    if (isRegistered) {
        resetRegiter();
        outputComponent = (
            <Redirect to="/thankyou" />
        );
    }

    return (
        <Route render={ () => (outputComponent) } />
    );
};

RouteWithLayout.propTypes = {
    /* eslint-disable react/forbid-prop-types */
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    isRegistered: PropTypes.bool.isRequired,
    resetRegiter: PropTypes.func.isRequired,
    setLanguage: PropTypes.func.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }),
    aboutLoading: PropTypes.bool.isRequired,
    progressLoading: PropTypes.bool.isRequired,
    homeLoading: PropTypes.bool.isRequired,
};

RouteWithLayout.defaultProps = {
    location: PropTypes.shape({
        pathname: '/',
    }),
};

const mapStateToProps = (state) => ({
    isRegistered: state.register.submitDone,
    aboutLoading: state.aboutus.loading,
    progressLoading: state.progress.loading,
    homeLoading: state.home.loading,
});

const mapDispatchToProps = {
    resetRegiter: closeGetInTouch,
    setLanguage: setLang,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RouteWithLayout));

