/* eslint-disable react/forbid-prop-types */
import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/styles';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Fade from '@material-ui/core/Fade';
import clsx from 'clsx';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import ZoomIcon from 'common/icon/zoom.svg';

const styles = theme => ({
    root: {
        height: 'auto',
        display: 'block',
    },
    media: {
        height: '100%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: '100vw',
        },
    },
    action: {
        width: 175,
        height: 70,
        right: 0,
        bottom: 0,
        boxSizing: 'border-box',
        position: 'absolute',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            width: '100%',
            height: 48,
            justifyContent: 'space-between',
        },
    },
    slideButton: {
        cursor: 'none',
        padding: 0,
        border: 'none',
    },
    hidden: {
        display: 'none',
    },
    bottomPart: {
    },
    // Dialog
    dialogPaper: {
        overflowY: 'visible',
        backgroundColor: '#fff0',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 0,
        },
    },
    backdrop: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#000',
        },
    },
    fullImg: {
        maxHeight: '80vh',
    },
    closeBtn: {
        position: 'absolute',
        top: '-50px',
        right: 0,
        color: '#fff',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.16)',
        },
    },
    actionBtn: {
        position: 'absolute',
        boxSizing: 'border-box',
        width: '100%',
        top: '50%',
    },
    actionInner: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        boxSizing: 'border-box',
        transform: 'translateY(-50%)',
    },
    zoom: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            width: 24,
            height: 24,
            marginBottom: 10,
            marginRight: 10,
            right: 0,
            bottom: 0,
            zIndex: 1,
            boxSizing: 'border-box',
            position: 'absolute',
        },
    },
    detail: {
        color: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
    },
});

function Materials(props) {
    const { classes, data } = props;

    const dataLength = data.length;
    const [state, setState] = React.useState({
        current: 0,
    });

    const nextData = () => {
        setState({
            ...state,
            current:
                state.current + 1 >= dataLength
                    ? state.current
                    : state.current + 1,
        });
    };

    const previousData = () => {
        setState({
            ...state,
            current: state.current <= 0 ? 0 : state.current - 1,
        });
    };

    const [open, setOpen] = React.useState(false);
    function handleClickOpen(num) {
        setState({
            ...state,
            current: num,
        });
        setOpen(true);
    }
    function handleClose() {
        setOpen(false);
    }

    return (
        <div className={classes.root}>
            <Hidden mdUp>
                <Box style={{ position: 'relative' }}>
                    {data.map((item, index) => (
                        <Fade
                            key={item.order}
                            in={index === state.current}
                            timeout={{ enter: 1000, exit: 500 }}
                            className={clsx(
                                index !== state.current
                                    ? classes.hidden
                                    : classes.media,
                            )}>
                            <CardMedia
                                component="img"
                                className={classes.media}
                                src={item}
                                onClick={() => handleClickOpen(index)}
                            />
                        </Fade>
                    ))}
                    <img
                        src={ZoomIcon}
                        alt="zoom icon"
                        className={classes.zoom}
                    />
                </Box>
                {/* Dialog */}
                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={open}
                    onClose={handleClose}
                    BackdropProps={{
                        classes: {
                            root: classes.backdrop,
                        },
                    }}
                    classes={{
                        paper: classes.dialogPaper,
                    }}>
                    <IconButton
                        onClick={handleClose}
                        className={classes.closeBtn}>
                        <CloseIcon />
                    </IconButton>
                    {data[state.current] && (
                        <>
                            <Box style={{ position: 'relative' }}>
                                <CardMedia
                                    component="img"
                                    alt="Holme Ekkamai 22"
                                    src={data[state.current]}
                                    title="Holme Ekkamai 22"
                                    className={classes.fullImg}
                                />
                                <Box
                                    className={classes.actionBtn}
                                    pl="16px"
                                    pr="16px">
                                    <Box className={classes.actionInner}>
                                        <IconButton
                                            size="small"
                                            style={{ color: '#fff' }}
                                            onClick={previousData}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            style={{ color: '#fff' }}
                                            onClick={nextData}>
                                            <ArrowForwardIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className={classes.detail} p="16px">
                                <Typography
                                    variant="button"
                                    style={{ color: '#fff' }}>
                                    {state.current + 1}/{dataLength}
                                </Typography>
                            </Box>
                        </>
                    )}
                </Dialog>
            </Hidden>
            <Box pt={{ xs: 2, md: '48px' }} className={classes.bottomPart}>
                <Container maxWidth={false}>
                    <Grid
                        spacing={4}
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start">
                        <Grid item xs={12} md={8}>
                            <CardActions
                                disableSpacing
                                className={classes.action}>
                                <IconButton
                                    size="small"
                                    onClick={previousData}
                                    style={{ color: '#fff' }}>
                                    <ArrowBackIcon />
                                </IconButton>
                                <Box pl={{ md: '20px' }} pr={{ md: '20px' }}>
                                    <Typography
                                        variant="body2"
                                        style={{ color: '#fff' }}>
                                        {state.current + 1}/{dataLength}
                                    </Typography>
                                </Box>
                                <IconButton
                                    size="small"
                                    onClick={nextData}
                                    style={{ color: '#fff' }}>
                                    <ArrowForwardIcon />
                                </IconButton>
                            </CardActions>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </div>
    );
}

Materials.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
};

export default withStyles(styles)(Materials);
