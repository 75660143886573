export const SHOW_SNACK = 'SHOW_SNACK';
export const HIDE_SNACK = 'HIDE_SNACK';
export const SET_LANG = 'SET_LANG';
export const SET_NOT_FOUND = 'SET_NOT_FOUND';
export const IS_SEARCHING = 'IS_SEARCHING';
export const SEARCH_LENGTH = 'SEARCH_LENGTH';

// Sync Action Creator
export function showSnack(status, message) {
    return {
        type: SHOW_SNACK,
        status,
        message,
    };
};
export function hideSnack() {
    return {
        type: HIDE_SNACK,
    };
};
export function setLang(lang) {
    return {
        type: SET_LANG,
        lang,
    };
};
export function setNotFound(value) {
    return {
        type: SET_NOT_FOUND,
        value
    };
};