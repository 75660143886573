import {
    ADD_DOWNLOAD,
    RENDER_DOWNLOAD,
    UPDATE_DOWNLOAD,
    ERROR_DOWNLOAD,
    CLOSE_DOWNLOAD
} from 'actions/download';

const initialState = {
    loading: false,
    submitDone: false, 
    email: '',
    error: '',
    isValid: false,
};

const downloadReducer = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_DOWNLOAD:
        return {
            ...state,
            email: action.newValue
        };
    case ERROR_DOWNLOAD:
        return {
            ...state,
            error: action.error,
        };
    case ADD_DOWNLOAD:
        return {
            ...state,
            loading: true,
        };
    case RENDER_DOWNLOAD:
        return {
            loading: false,
            submitDone: true, 
            email: '',    
            error: '',
        };
    case CLOSE_DOWNLOAD:
        return {
            ...state,
            submitDone: false,
        };
    default:
        return state;
    }
};
export default downloadReducer;