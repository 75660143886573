import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Line from './line.svg';
import RedSegment from './redSegment.svg';

const styles = theme => ({
    root: {
        position: 'relative',
        backgroundColor: '#fff',
        width: '100%',
        height: 'auto',
        textAlign: 'center',
        padding: '144px 0 62px 0',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            padding: '66px 0 62px 0',
        },
    },
    card: {
        borderRadius: 0,
        boxShadow: 'none',
    },
    media: {
        // height:365,
        // [theme.breakpoints.down('sm')]: {
        //     height: 184,
        // }
    },
    content: {
        backgroundColor: '#922d38',
        padding: '0 116px 80px 116px !important',
        [theme.breakpoints.down('sm')]: {
            padding: '0 16px 48px 16px !important',
        },
    },
    outerMedia: {
        position: 'relative',
    },
    segment: {
        position: 'absolute',
        bottom: '-1px',
        left: 0,
        width: '100%',
        height: 'auto',
    },
    button: {
        minWidth: 280,
        padding: 20,
        borderRadius: 0,
        color: '#82bad1',
        margin: '8px 16px',
        '& img': {
            marginRight: 8,
        },
        '&:hover': {
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            padding: 14,
            width: '100%',
            margin: '0 0 16px 0',
        },
    },
});

function Thankyou(props) {
    const { classes, image } = props;
    console.log(image);

    return (
        <div className={classes.root}>
            <Container maxWidth={false}>
                <Grid
                    spacing={4}
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start">
                    <Grid item xs={12} md={8}>
                        <Card className={classes.card}>
                            <Box className={classes.outerMedia}>
                                <CardMedia
                                    className={classes.media}
                                    component="img"
                                    alt="Thank you"
                                    image={image}
                                    title="Thank you"
                                />
                                <img
                                    src={RedSegment}
                                    alt="thank you"
                                    className={classes.segment}
                                />
                            </Box>
                            <CardContent className={classes.content}>
                                <Box pb="14px">
                                    <Typography
                                        variant="h1"
                                        style={{ color: '#fff' }}>
                                        ลงทะเบียนเรียบร้อย
                                    </Typography>
                                </Box>
                                <Box pb="28px">
                                    <Typography
                                        variant="subtitle1"
                                        style={{ color: '#fff' }}>
                                        ขอบคุณที่สนใจโครงการ The Moment Ratchada
                                        19
                                        ทางฝ่ายขายจะติดต่อคุณกลับไปโดยเร็วที่สุด
                                    </Typography>
                                </Box>
                                <Box className={classes.btnGroup}>
                                    <Button
                                        className={classes.button}
                                        color="primary"
                                        variant="outlined"
                                        href="/">
                                        กลับหน้าหลัก
                                    </Button>
                                    <Button
                                        className={classes.button}
                                        color="primary"
                                        variant="contained"
                                        href="https://lin.ee/wBWyRon"
                                        target="_blank">
                                        <img src={Line} alt="line icon" />
                                        <Typography
                                            variant="button"
                                            style={{ color: '#922d38' }}>
                                            {` @themomentcondo`}
                                        </Typography>
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        image: state.home.data.register[0].img,
    };
};

Thankyou.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    image: PropTypes.string,
};

Thankyou.defaultProps = {
    image: '',
};

export default connect(mapStateToProps, null)(withStyles(styles)(Thankyou));
