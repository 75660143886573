import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import CardMedia from '@material-ui/core/CardMedia';

const styles = theme => ({
    root: {
        position: 'relative',
        backgroundColor: '#fff',
        width: '100%',
        height: 'auto',
        textAlign: 'center',
        padding: '80px 0 62px 0',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            padding: '50px 0 62px 0',
        }
    },
    media:{
        height:756,
        [theme.breakpoints.down('sm')]: {
            height: 200,
        }
    },
});

function Thankyou(props) {
    const {
        classes,
        location,
        data,
        getData,
    } = props;

    const { pathname } = location;
    const lang = pathname.split("/")[1];

    React.useEffect(() => {
        getData(lang);
    }, [getData, lang]);

    return (
        <div className={classes.root}>
            <Hidden mdUp>
                {
                    data.img && 
                    <CardMedia
                        className={classes.media}
                        component="img"
                        alt="About Us"
                        image={data.img}
                        title="About Us"
                    />
                }
            </Hidden>
            <Container maxWidth={false}>
                <Grid
                    spacing={4}
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                >
                    <Hidden smDown>
                        <Grid item xs={12} >
                            {
                                data.img && 
                                <CardMedia
                                    className={classes.media}
                                    component="img"
                                    alt="About Us"
                                    image={data.img}
                                    title="About Us"
                                />
                            }
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} >
                        <Box pt={{ xs: "64px", md: 0 }} pb={{ xs: "24px", md:"26px"}}>
                            <Typography variant="h2" color="textPrimary">
                                ABOUT US
                            </Typography>
                        </Box>
                        <Box pb={{ xs: "16px", md: 0 }}>
                            <Typography variant="body1" color="textSecondary">
                                {data.desc}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

Thankyou.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
        img: PropTypes.string.isRequired,
        desc: PropTypes.string.isRequired,
    }).isRequired,
    getData: PropTypes.func.isRequired,
};

Thankyou.defaultProps = {
    location: PropTypes.shape({
        pathname: '/',
    }),
};

export default withRouter(withStyles(styles)(Thankyou));