import {
    FETCH_PROGRESS,
    FETCH_PROGRESS_DONE,
    FETCH_PROGRESS_FAIL,
} from 'actions/progress';

const initialState = {
    loading: true,
    data: {
        img: '',
        desc: '',
        percent: 0,
    },
    failMessage: '',
};

const progressReducer = (state = initialState, action) => {
    switch (action.type) {
    case FETCH_PROGRESS:
        return {
            ...state,
            loading: true,
        };
    case FETCH_PROGRESS_FAIL:
        return {
            ...state,
            loading: true,
            failMessage: action.message,
        };
    case FETCH_PROGRESS_DONE:
        return {
            ...state,
            loading: false,
            data: action.data,
        };
    default:
        return state;
    }
};
export default progressReducer;
