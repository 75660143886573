/* eslint-disable react/forbid-prop-types */
import React from 'react';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/styles';
import Hidden from '@material-ui/core/Hidden';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MenuItem from '@material-ui/core/MenuItem';
import DesktopTop from './desktopTop.svg';
import PlanSelect from './plan';
import TabBg from './tab.svg';

const styles = theme => ({
    root: {
        height: 'auto',
        display: 'block',
        backgroundColor: '#922d38',
        overscrollBehaviorX: 'contain',
        maxWidth: '100%',
        overflowX: 'hidden',
    },
    title: {
        padding: '7px 0 7px 20px',
        borderLeft: 'solid 3px #fff',
        [theme.breakpoints.down('sm')]: {
            padding: '6px 0 6px 16px',
        },
    },
    top: {
        position: 'absolute',
        width: '100%',
        top: 0,
        left: 0,
        right: 0,
        transform: 'scaleY(-1)',
        transformOrigin: 'top left',
    },
    rootTwo: {
        backgroundColor: '#f3f2ec',
        position: 'relative',
        '& img': {
            position: 'relative',
            width: '100%',
            // height: 155,
            bottom: '-1px',
            display: 'block',
            // position: 'absolute',
        },
    },
    paper: {
        backgroundColor: '#fff',
        padding: 31,
        [theme.breakpoints.down('sm')]: {
            padding: '20px 16px 54px 16px',
            borderRadius: 0,
        },
    },
    tab: {
        display: 'flex',
        cursor: 'pointer',
    },
    leftTab: {
        width: '50%',
        height: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    activeTab: {
        backgroundImage: `url(${TabBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPositionY: 'bottom',
    },
    selectForm: {
        color: '#fff',
        fontWeight: 'bold',
        maxWidth: 414,
        width: '100%',
        margin: '0 0 30px 0',
        [theme.breakpoints.down('sm')]: {
            margin: '0 0 40px 0',
        },
        '& .MuiInputBase-root': {
            color: '#fff',
            borderColor: '#fff',
        },
        '&& .MuiInput-root:hover::before': {
            borderColor: '#fff',
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #fff',
        },
        '& .MuiSelect-icon': {
            color: '#fff',
        },
        '& .MuiSelect-select': {
            borderColor: '#fff',
            height: 32,
            fontSize: '1.5rem',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.33,
            letterSpacing: 'normal',
            [theme.breakpoints.down('sm')]: {
                height: 24,
                fontSize: '1.25rem',
                lineHeight: 1.4,
            },
        },
        '& #floor': {
            color: '#fff',
            fontSize: '1.5rem',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.33,
            letterSpacing: 'normal',
            '@media screen and (max-width: 961px)': {
                fontSize: '1.25rem',
                lineHeight: 1.4,
            },
        },
    },
    selectList: {
        color: '#922d38',
        fontSize: '1.5rem',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
        '@media screen and (max-width: 961px)': {
            fontSize: '1.25rem',
            lineHeight: 1.4,
        },
    },
});

function ContactUs(props) {
    const { classes, unit, floor } = props;

    // Tab
    const [checked, setChecked] = React.useState(true);
    const handleChange = value => {
        setChecked(value);
    };

    const [state, setState] = React.useState({
        current: 0,
    });
    const handleSelect = event => {
        if (Number(event.target.value) === 0) {
            setChecked(true);
        } else {
            setChecked(false);
        }

        setState({
            ...state,
            current: Number(event.target.value),
        });
    };

    return (
        <div className={classes.root} id="plan">
            <Hidden smDown>
                <div className={classes.rootTwo}>
                    <img src={DesktopTop} alt="facility" />
                </div>
            </Hidden>
            <Box pb={{ xs: 0, md: 7 }} pt={{ xs: 5, md: 2 }}>
                <Container>
                    <Grid spacing={0} container>
                        <Grid item xs={12}>
                            <Box pb={{ xs: 6, md: 7 }}>
                                <Box className={classes.title}>
                                    <Typography
                                        variant="h2"
                                        style={{ color: '#fff' }}>
                                        PROJECT PLAN
                                    </Typography>
                                </Box>
                            </Box>
                            <Hidden mdUp>
                                <Box>
                                    <Typography
                                        variant="caption"
                                        component="p"
                                        style={{ color: '#fff' }}>
                                        PROJECT PLAN
                                    </Typography>
                                    <FormControl className={classes.selectForm}>
                                        <Select
                                            value={state.current}
                                            onChange={handleSelect}
                                            IconComponent={ArrowDownwardIcon}
                                            inputProps={{
                                                name: 'floor',
                                                id: 'floor',
                                            }}>
                                            <MenuItem
                                                className={classes.selectList}
                                                value={0}>
                                                FLOOR PLAN
                                            </MenuItem>
                                            <MenuItem
                                                className={classes.selectList}
                                                value={1}>
                                                UNIT PLAN
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Hidden>
                        </Grid>
                    </Grid>
                    <Hidden smDown>
                        <Grid
                            spacing={0}
                            container
                            direction="row"
                            justify="center"
                            alignItems="flex-start">
                            <Grid item xs={12}>
                                <Box className={classes.tab}>
                                    <Box
                                        className={clsx(
                                            classes.leftTab,
                                            checked && classes.activeTab,
                                        )}
                                        onClick={() => handleChange(true)}>
                                        <Typography
                                            variant="button"
                                            color={
                                                checked
                                                    ? 'secondary'
                                                    : 'primary'
                                            }>
                                            FLOOR PLAN
                                        </Typography>
                                    </Box>
                                    <Box
                                        className={clsx(
                                            classes.leftTab,
                                            !checked && classes.activeTab,
                                        )}
                                        onClick={() => handleChange(false)}>
                                        <Typography
                                            variant="button"
                                            color={
                                                !checked
                                                    ? 'secondary'
                                                    : 'primary'
                                            }>
                                            UNIT PLAN
                                        </Typography>
                                    </Box>
                                </Box>
                                {checked ? (
                                    <Paper
                                        elevation={4}
                                        className={classes.paper}>
                                        <PlanSelect type="floor" data={floor} />
                                    </Paper>
                                ) : (
                                    <Paper
                                        elevation={4}
                                        className={classes.paper}>
                                        <PlanSelect type="unit" data={unit} />
                                    </Paper>
                                )}
                            </Grid>
                        </Grid>
                    </Hidden>
                </Container>
                <Hidden mdUp>
                    {checked ? (
                        <Paper elevation={4} className={classes.paper}>
                            <PlanSelect type="floor" data={floor} />
                        </Paper>
                    ) : (
                        <Paper elevation={4} className={classes.paper}>
                            <PlanSelect type="unit" data={unit} />
                        </Paper>
                    )}
                </Hidden>
            </Box>
        </div>
    );
}

ContactUs.propTypes = {
    classes: PropTypes.object.isRequired,
    unit: PropTypes.array.isRequired,
    floor: PropTypes.array.isRequired,
};

export default withStyles(styles)(ContactUs);
