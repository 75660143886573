/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Hidden from '@material-ui/core/Hidden';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MobileGallery from './mobile';

const styles = theme => ({
    root: {
        overscrollBehaviorX: 'contain',
        maxWidth: '100%',
        overflowX: 'hidden',
        height: 'auto',
        display: 'block',
        backgroundColor: '#922d38',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 120,
        },
    },
    title: {
        padding: '7px 0 7px 20px',
        borderLeft: 'solid 3px #fff',
        [theme.breakpoints.down('sm')]: {
            padding: '6px 0 6px 16px',
        },
    },
    lastBox: {
        position: 'relative',
        height: 512,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingLeft: 84,
        [theme.breakpoints.down('sm')]: {
            height: 246,
        },
    },
    lastBtn: {
        width: 232,
        height: 64,
        borderRadius: 0,
        color: '#922d38',
    },
    play: {
        height: 64,
        width: 184,
        borderRadius: 0,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: 64,
            height: 64,
        },
    },
    video: {
        position: 'relative',
        cursor: 'pointer',
        backgroundBlendMode: 'multiply',
        backgroundColor: 'transparent',
        transition: 'all 0.5s ease',
        display: 'flex',
        justifyContent: 'center',
        '& button': {
            opacity: 0,
        },
        '&:hover': {
            backgroundColor: '#932d43f0',
            '& button': {
                opacity: 1,
            },
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 40,
        },
    },
    landscape: {
        height: 512,
        [theme.breakpoints.down('sm')]: {
            height: 246,
        },
    },
    dialogPaper: {
        overflowY: 'visible',
        backgroundColor: '#fff0',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 0,
        },
    },
    backdrop: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#000',
        },
    },
    closeBtn: {
        position: 'absolute',
        top: '-50px',
        right: 0,
        color: '#fff',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.16)',
        },
    },
    fullImg: {
        maxWidth: '100%',
        height: 'auto',
        maxHeight: '80vh',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 250,
        },
    },
    actionBtn: {
        position: 'absolute',
        boxSizing: 'border-box',
        width: '100%',
        top: '50%',
    },
    actionInner: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        boxSizing: 'border-box',
        transform: 'translateY(-50%)',
    },
});

function ContactUs(props) {
    const { classes, data } = props;
    // console.log(data);

    const first = data.slice(0, 5);
    let second = [];
    if (data.length > 5) {
        second = data.slice(5);
    }

    // Dialog
    const [number, setNumber] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    function handleClickOpen(num) {
        setNumber(num);
        setOpen(true);
    }
    function handleClose() {
        setOpen(false);
    }

    // Collapse
    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked(prev => !prev);
    };

    const dataLength = data.length;
    const nextData = () => {
        if (number + 1 >= dataLength){
            setNumber(number);
        }else{
            setNumber(number+1);
        }
    };

    const previousData = () => {
        if (number <= 0) {
            setNumber(0);
        } else {
            setNumber(number - 1);
        }
    };

    return (
        <div className={classes.root} id="gallery">
            <Hidden smDown>
                <Grid
                    spacing={0}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start">
                    {first.map((item, order) => (
                        <Grid item xs={4} key={order}>
                            <CardMedia
                                component="div"
                                alt="The Moment"
                                className={clsx(
                                    classes.landscape,
                                    classes.video,
                                )}
                                image={item}
                                title="The Moment"
                                onClick={() => handleClickOpen(order)}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.play}>
                                    ขยาย
                                </Button>
                                <Typography variant="caption" style={{
                                    color: '#fff', 
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                    margin: 8,
                                }}>
                                    COMPUTER GENERATED
                                </Typography>
                            </CardMedia>
                        </Grid>
                    ))}

                    {second.length > 0 &&
                        checked &&
                        second.map((item, order) => (
                            <Grid item xs={4} key={5 + order}>
                                <CardMedia
                                    component="div"
                                    alt="The Moment"
                                    className={clsx(
                                        classes.landscape,
                                        classes.video,
                                    )}
                                    image={item}
                                    title="The Moment"
                                    onClick={() => handleClickOpen(order+5)}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className={classes.play}>
                                        ขยาย
                                    </Button>
                                    <Typography variant="caption" style={{
                                        color: '#fff',
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 0,
                                        margin: 8,
                                    }}>
                                        COMPUTER GENERATED
                                    </Typography>
                                </CardMedia>
                            </Grid>
                        ))}
                    <Grid item xs={4}>
                        <Box className={classes.lastBox}>
                            <Box className={classes.title} mb={5}>
                                <Typography
                                    variant="h2"
                                    style={{ color: '#fff' }}>
                                    GALLERY
                                </Typography>
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.lastBtn}
                                onClick={handleChange}>
                                {!checked ? 'ขยาย': 'กลับ'}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                {/* Dialog */}
                <Dialog
                    maxWidth="md"
                    open={open}
                    onClose={handleClose}
                    BackdropProps={{
                        classes: {
                            root: classes.backdrop,
                        },
                    }}
                    classes={{
                        paper: classes.dialogPaper,
                    }}>
                    <IconButton
                        onClick={handleClose}
                        className={classes.closeBtn}>
                        <CloseIcon />
                    </IconButton>
                    <Box style={{ position: 'relative' }}>
                        {data[number] && (
                            <>
                                <CardMedia
                                    component="img"
                                    alt="The Moment"
                                    src={data[number]}
                                    title="The Moment"
                                    className={classes.fullImg}
                                />
                                <Box
                                    className={classes.actionBtn}
                                    pl="16px"
                                    pr="16px">
                                    <Box className={classes.actionInner}>
                                        <IconButton
                                            size="small"
                                            style={{ color: '#fff' }}
                                            onClick={previousData}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            style={{ color: '#fff' }}
                                            onClick={nextData}>
                                            <ArrowForwardIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Box>
                </Dialog>
            </Hidden>
            <Hidden mdUp>
                <Box pl={2} pr={2}>
                    <Box className={classes.title} mb={5}>
                        <Typography variant="h2" style={{ color: '#fff' }}>
                            GALLERY
                        </Typography>
                    </Box>
                </Box>
                <MobileGallery data={data} />
            </Hidden>
        </div>
    );
}

ContactUs.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
};

export default withStyles(styles)(ContactUs);
