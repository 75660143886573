import { connect } from 'react-redux';
import {
    getData
} from 'actions/home';
import Home from 'views/home';

const mapStateToProps = state => {
    return {
        data: state.home.data,
        loading: state.home.loading,
    };
};

const mapDispatchToProps = {
    getData,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
