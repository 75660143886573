import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

const styles = (theme) => ({
    cta: {
        textDecoration: 'none',
    },
    button: {
        width: '100%',
        minHeight: '64px',
        cursor: 'pointer',
        borderRadius: '0',
        color: '#922d38',
        backgroundColor: '#82bad1',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minHeight: '48px',
        },
    },
    white:{
        color: '#33312e',
        boxShadow: 'none',
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#fff',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#fff',
        },
        [theme.breakpoints.down('sm')]: {
            height: 50,
        },
    }
});

function CTA(props) {
    const { children, url, onClick, classes, color } = props;

    return (
        <>
            {
                url ?
                    <a className={classes.cta} href={url}>
                        <Button 
                            className={clsx(classes.button, color === 'white' && classes.white)}
                            variant="contained"
                            color={color === 'white' ? 'default' : color}
                            onClick={onClick}
                        >
                            {children}
                        </Button>
                    </a>
                    :
                    <Button 
                        className={clsx(classes.button, color === 'white' && classes.white)}
                        variant="contained"
                        color={color === 'white' ? 'default' : color}
                        onClick={onClick}
                    >
                        {children}
                    </Button>
            }
        </>
    );
}

CTA.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    children: PropTypes.string.isRequired,
    color: PropTypes.string,
    url: PropTypes.string,
    onClick: PropTypes.func,
};

CTA.defaultProps = {
    url: '',
    color: 'default',
    onClick: () => console.log('click'),
};

export default withStyles(styles)(CTA);