import { combineReducers } from 'redux';
import register from './register';
import main from './main';
import fixFooter from './fixFooter';
import download from './download';
import aboutus from './aboutus';
import progress from './progress';
import home from './home';

export default combineReducers({
    main,
    register,
    fixFooter,
    download,
    aboutus,
    progress,
    home,
});