/* eslint-disable react/forbid-prop-types */
import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    root: {
        overscrollBehaviorX: 'contain',
        maxWidth: '100%',
        overflowX: 'hidden',
        backgroundColor: '#f3f2ec',
        height: 'auto',
        display: 'block',
        paddingTop: 110,
        paddingBottom: 123,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 40,
            paddingBottom: 40,
        },
    },
    desc: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    readmore: {
        textAlign: 'center',
        cursor: 'pointer',
        textDecoration: 'underline',
    },
});

function Concept(props) {
    const { classes } = props;

    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked(prev => !prev);
    };

    return (
        <div className={classes.root} id="concept">
            {/* Desktop */}
            <Container>
                <Grid
                    spacing={0}
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start">
                    <Grid item xs={12} md={10}>
                        <Box
                            pb={{ xs: 3, md: '20px' }}
                            style={{ textAlign: 'center' }}>
                            <Typography variant="h1" color="secondary">
                                PROJECT CONCEPT
                            </Typography>
                        </Box>
                        <Box
                            pb={{ xs: 4, md: '32px' }}
                            style={{ textAlign: 'center' }}>
                            <Typography variant="h3" color="secondary">
                                AMAZE A MOMENT
                                <br />
                                เริ่มโมเมนต์ใหม่ ให้ชีวิตอเมซ...
                            </Typography>
                        </Box>
                        <Box style={{ textAlign: 'center' }}>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                className={classes.desc}>
                                สัมผัสบริบทใหม่ของการอยู่อาศัยที่ให้คุณอเมซในทุกโมเมนต์
                                สะท้อนแรงบันดาลใจแห่งความสุขง่ายๆ
                                ในมุมที่แตกต่าง ตลอดทุกช่วงเวลาของชีวิต
                            </Typography>
                            <Hidden smDown>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    className={classes.desc}>
                                    <br />
                                    ตอบโจทย์ทุกด้านของการใช้ชีวิตใจกลางเมืองพร้อมความเป็นส่วนตัวที่มากกว่า
                                    กับพรีเมียมคอนโดอาคารหรูล้ำสมัยในแบบเฉพาะตัวผ่าน
                                    POLARIZED MODERN DESIGN
                                    เชื่อมการอยู่อาศัยไปกับแสงเงา
                                    ภายใต้แนวคิดการสร้างสรรค์และสะท้อนความงาม
                                    ผ่านจังหวะ ทิศทางของแสง
                                    และเหลี่ยมมุมของรูปทรง
                                    <br />
                                    <br />
                                    เพียง 450 เมตร จาก MRT รัชดา ตกแต่งแบบ
                                    Boutique Luxury Design พร้อม Exclusive High
                                    Privacy เพียง 134 ยูนิต
                                </Typography>
                            </Hidden>
                            <Hidden mdUp>
                                <Collapse in={checked}>
                                    <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        className={classes.desc}>
                                        <br />
                                        ตอบโจทย์ทุกด้านของการใช้ชีวิตใจกลางเมืองพร้อมความเป็นส่วนตัวที่มากกว่า
                                        กับพรีเมียมคอนโดอาคารหรูล้ำสมัยในแบบเฉพาะตัวผ่าน
                                        POLARIZED MODERN DESIGN
                                        เชื่อมการอยู่อาศัยไปกับแสงเงา
                                        ภายใต้แนวคิดการสร้างสรรค์และสะท้อนความงาม
                                        ผ่านจังหวะ ทิศทางของแสง
                                        และเหลี่ยมมุมของรูปทรง
                                        <br />
                                        <br />
                                        เพียง 450 เมตร จาก MRT รัชดา ตกแต่งแบบ
                                        Boutique Luxury Design พร้อม Exclusive
                                        High Privacy เพียง 134 ยูนิต
                                    </Typography>
                                </Collapse>
                                <Box mt={3}>
                                    <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        onClick={handleChange}
                                        className={classes.readmore}>
                                        {checked
                                            ? 'ซ่อนเนื้อหา'
                                            : 'อ่านเพิ่มเติม'}
                                    </Typography>
                                </Box>
                            </Hidden>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

Concept.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Concept);
