/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import RegisterSection from 'components/sections/register';
import ProjectConceptSection from 'components/sections/projectConcept';
import FacilitySection from 'components/sections/facility';
import DownloadSection from 'components/sections/download';
import ProjectPlanSection from 'components/sections/projectPlan';
import GallerySection from 'components/sections/gpGallery';
import VideoSection from 'components/sections/video';
import ContactSection from 'components/sections/contact';
import WhoWeAreSection from 'components/sections/whoare';
import ProgressSection from 'components/sections/progress';

function Home(props) {
    const { data, getData } = props;

    React.useEffect(() => {
        getData();
    }, [getData]);

    return (
        <>
            <RegisterSection data={data.register} />
            <ProjectConceptSection />
            <FacilitySection />
            <DownloadSection data={data.info} />
            <ProjectPlanSection unit={data.unitplan} floor={data.floorplan} />
            <GallerySection data={data.gallery} />
            <VideoSection data={data.video} />
            <ContactSection data={data.contactUs} />
            <WhoWeAreSection data={data.info} />
            <ProgressSection data={data.progress} />
        </>
    );
}

Home.propTypes = {
    data: PropTypes.object.isRequired,
    getData: PropTypes.func.isRequired,
};

export default Home;
