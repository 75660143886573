/* eslint-disable react/forbid-prop-types */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';
import Top from './top.svg';
import Bottom from './bottom.svg';
import MobileBottom from './mobileBottom.svg';
import MobileTop from './mobileTop.svg';
import Cover from './owner.jpg';

const styles = theme => ({
    outer:{
        overscrollBehaviorX: 'contain',
        maxWidth: '100%',
        overflowX: 'hidden',
    },
    root: {
        backgroundColor: '#f3f2ec',
        height: 'auto',
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 40,
            paddingBottom: 0,
        },
    },
    media: {
        position: 'relative',
        height: 928,
        [theme.breakpoints.down('sm')]: {
            height: 480,
        },
    },
    top: {
        top: 0,
        left: '-1px',
        position: 'absolute',
        [theme.breakpoints.down('sm')]: {
            top: '-1px',
            right: 0,
            left: 0,
            width: '100%',
        },
    },
    bottom: {
        bottom: 0,
        left: '-1px',
        position: 'absolute',
        [theme.breakpoints.down('sm')]: {
            bottom: '-1px',
            right: 0,
            left: 0,
            width: '100%',
        },
    },
    right: {
        display: 'flex',
        marginLeft: 'auto',
        paddingLeft: 16,
        marginRight: 48,
        maxWidth: 550,
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 16,
            marginRight: 16,
            paddingLeft: 0,
            height: 'auto',
        },
    },
    title: {
        padding: '8px 0 8px 20px',
        borderLeft: 'solid 3px #922d38',
        [theme.breakpoints.down('sm')]: {
            padding: '8px 0 8px 16px',
        },
    },
    list: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 18,
        },
    },
    exp: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
});

function Download(props) {
    const { classes } = props;

    return (
        <div className={classes.outer}>
            <Hidden mdUp>
                <CardMedia
                    component="div"
                    alt="The Moment"
                    className={classes.media}
                    image={Cover}
                    title="The Moment">
                    <Hidden mdUp>
                        <img
                            src={MobileTop}
                            className={classes.top}
                            alt="project info"
                        />
                        <img
                            src={MobileBottom}
                            className={classes.bottom}
                            alt="project info"
                        />
                    </Hidden>
                    <Hidden smDown>
                        <img
                            src={Top}
                            className={classes.top}
                            alt="project info"
                        />
                        <img
                            src={Bottom}
                            className={classes.bottom}
                            alt="project info"
                        />
                    </Hidden>
                </CardMedia>
            </Hidden>
            <div className={classes.root}>
                <Grid
                    spacing={0}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Box className={classes.right}>
                            <Box mb={8} className={classes.title}>
                                <Typography variant="h2" color="secondary">
                                    WHO WE ARE
                                </Typography>
                            </Box>
                            <Box mb={{ xs: 3, md: 1 }}>
                                <Typography variant="h3" color="secondary">
                                    ดร. ณธเดช สุนทรปกาสิต
                                    <br />
                                    Dr. Nathadech Sunthonpagasit
                                </Typography>
                            </Box>
                            <Box mb={6}>
                                <Typography variant="body1" color="secondary">
                                    ที่ปรึกษาและเป็นนักพัฒนาอสังหาริมทรัพย์มากว่า
                                    10 ปี
                                </Typography>
                            </Box>
                            <Box className={classes.exp}>
                                <Box>
                                    <Box mb="20px">
                                        <Typography
                                            variant="h3"
                                            color="secondary">
                                            PROJECT OWNER
                                        </Typography>
                                    </Box>
                                    <Box mb="28px">
                                        <Typography
                                            variant="subtitle2"
                                            color="secondary">
                                            Moment of Living Asset Co., Ltd.
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            color="secondary">
                                            - The Moment Ratchada 19
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            variant="subtitle2"
                                            color="secondary">
                                            Pagasit & Son Asset Co., Ltd.
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            color="secondary">
                                            - De Zone Chaengwattana
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    mt={{ xs: 4, md: 0 }}
                                    mb={{ xs: 6, md: 0 }}>
                                    <Box mb="20px">
                                        <Typography
                                            variant="h3"
                                            color="secondary">
                                            PARTNER
                                        </Typography>
                                    </Box>
                                    <Box mb="28px">
                                        <Typography
                                            variant="subtitle2"
                                            color="secondary">
                                            Library Estate Co., Ltd.
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            color="secondary">
                                            - Philo Ladprao 18
                                            <br />
                                            - The Novel Ladprao 18
                                            <br />
                                            - Philo Ekkamai 6<br />- The
                                            Commerce Prachauthit – Suksawat
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            variant="subtitle2"
                                            color="secondary">
                                            TRYN Asset
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            color="secondary">
                                            - The Author Ratchada 32
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={12} md={6}>
                            <CardMedia
                                component="div"
                                alt="The Moment"
                                className={classes.media}
                                image={Cover}
                                title="The Moment">
                                <img
                                    src={Top}
                                    className={classes.top}
                                    alt="project info"
                                />
                                <img
                                    src={Bottom}
                                    className={classes.bottom}
                                    alt="project info"
                                />
                            </CardMedia>
                        </Grid>
                    </Hidden>
                </Grid>
            </div>
        </div>
    );
}

Download.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Download);
