/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Fade from '@material-ui/core/Fade';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Compass from '../compass.svg';

const styles = theme => ({
    root: {
        height: 'auto',
        display: 'block',
    },
    media: {
        height: '100%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: 246,
        },
    },
    hidden: {
        display: 'none',
    },
    right: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
    selectForm: {
        color: '#922d38',
        fontWeight: 'bold',
        maxWidth: 414,
        width: '100%',
        margin: '0 0 30px 0',
        [theme.breakpoints.down('sm')]: {
            margin: '0 0 54px 0',
        },
        '& .MuiInputBase-root': {
            color: '#922d38',
        },
        '&& .MuiInput-root:hover::before': {
            borderColor: '#922d38',
        },
        '& .MuiSelect-select': {
            color: '#922d38',
            height: 32,
            fontSize: '1.5rem',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.33,
            letterSpacing: 'normal',
            [theme.breakpoints.down('sm')]: {
                height: 24,
                fontSize: '1.25rem',
                lineHeight: 1.4,
            },
        },
        '& #floor': {
            color: '#922d38',
            fontSize: '1.5rem',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.33,
            letterSpacing: 'normal',
            '@media screen and (max-width: 961px)': {
                fontSize: '1.25rem',
                lineHeight: 1.4,
            },
        },
    },
    selectList: {
        color: '#922d38',
        fontSize: '1.5rem',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
        '@media screen and (max-width: 961px)': {
            fontSize: '1.25rem',
            lineHeight: 1.4,
        },
    },
    // Dialog
    dialogPaper: {
        overflowY: 'visible',
        backgroundColor: '#fff0',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 0,
        },
    },
    backdrop: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#000',
        },
    },
    fullImg: {
        maxHeight: '80vh',
    },
    closeBtn: {
        position: 'absolute',
        top: '-50px',
        right: 0,
        color: '#fff',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.16)',
        },
    },
    wrapMedia:{
        position: 'relative',
    },
    zoom: {
        display: 'block',
        width: 50,
        height: 50,
        marginBottom: 16,
        marginRight: 16,
        right: 0,
        bottom: 0,
        zIndex: 1,
        boxSizing: 'border-box',
        position: 'absolute',
        [theme.breakpoints.down('sm')]: {
            width: 24,
            height: 24,
            marginBottom: 10,
            marginRight: 10,
        },
    },
});

function FloorPlan(props) {
    const { classes, type, data } = props;

    const selectList = data;

    const [state, setState] = React.useState({
        current: 0,
    });

    const handleSelect = event => {
        setState({
            ...state,
            current: Number(event.target.value),
        });
    };

    return (
        <div className={classes.root} id="floorplan">
            <Box>
                <Typography variant="caption" component="p" color="secondary">
                    {type === 'floor' ? 'FLOOR PLAN' : 'UNIT PLAN'}
                </Typography>
                <FormControl className={classes.selectForm}>
                    <Select
                        value={state.current}
                        onChange={handleSelect}
                        IconComponent={ArrowDownwardIcon}
                        inputProps={{
                            name: 'floor',
                            id: 'floor',
                        }}>
                        {selectList.map((item, index) => (
                            <MenuItem
                                className={classes.selectList}
                                key={item.order}
                                value={index}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box style={{ position: 'relative' }}>
                {selectList.map((item, index) => (
                    <Box key={item.order}>
                        <Fade
                            in={index === state.current}
                            timeout={{ enter: 1000, exit: 500 }}
                            className={clsx(
                                index !== state.current
                                    ? classes.hidden
                                    : classes.media,
                            )}>
                            <div className={classes.wrapMedia}>
                                <CardMedia
                                    component="img"
                                    className={classes.media}
                                    src={item.img}
                                />
                                {type === 'floor' && <img src={Compass} className={classes.zoom} alt="icon" />}
                            </div>
                        </Fade>
                    </Box>
                ))}
            </Box>
        </div>
    );
}

FloorPlan.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
};

export default withStyles(styles)(FloorPlan);
