import React from 'react';
import { withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// import Button from '@material-ui/core/Button';
// import Drawer from '@material-ui/core/Drawer';
// import Fab from '@material-ui/core/Fab';

// Image
import Logo from 'common/icon/logo-full.svg';
import BLogo from 'common/icon/logo.svg';
// import HBger from './hamburger.svg';
// import WHBger from './w-hamburger.svg';
// import Close from './close.svg';
// import Mobile from './mobile.svg';
import FB from './facebook.svg';
import Line from './line.svg';
// import Instagram from './instagram.svg';
import RedFB from './red/facebook.svg';
import RedLine from './red/line.svg';
// import RedIG from './red/instagram.svg';
import RedMobile from './red/mobile.svg';
import Mobile from './mobile.svg';

const styles = theme => ({
    nav: {
        position: 'fixed',
        top: 0,
        width: '100%',
        boxSizing: 'border-box',
        zIndex: 999,
        transition: 'all 300ms linear',
        WebkitTransition: 'all 300ms linear',
        MozTransition: 'all 300ms linear',
        OTransition: 'all 300ms linear',
        willChange: 'top',
    },
    innerNav: {
        display: 'flex',
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
        },
    },
    homeTopNav: {
        top: 5,
        [theme.breakpoints.down('sm')]: {
            top: 0,
        },
    },
    background: {
        width: '100%',
        display: 'block',
        transition: 'all 300ms linear',
        WebkitTransition: 'all 300ms linear',
        MozTransition: 'all 300ms linear',
        OTransition: 'all 300ms linear',
        willChange: 'background',
        paddingTop: 15,
        paddingBottom: 15,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 12,
            paddingBottom: 12,
        },
    },
    white: {
        // paddingTop: 15,
        boxShadow: '0 4px 20px 0 rgba(83, 83, 83, 0.2)',
        backgroundColor: '#fff',
    },
    transparent: {
        backgroundColor: 'transparent',
    },
    left: {
        display: 'inline-block',
        textAlign: 'left',
        width: '25%',
        paddingTop: 12,
        '& img': {
            width: 'auto',
            display: 'inline-block',
            verticalAlign: 'middle',
            objectFit: 'contain',
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
            width: '30%',
        },
    },
    logoWhite: {
        height: 77,
        [theme.breakpoints.down('sm')]: {
            height: 40,
        },
    },
    logoBlack: {
        height: 25,
        [theme.breakpoints.down('sm')]: {
            height: 24,
        },
    },
    right: {
        display: 'inline-block',
        textAlign: 'right',
        width: '75%',
        '& a': {
            display: 'inline-block',
            verticalAlign: 'middle',
            objectFit: 'contain',
            textDecoration: 'none',
            color: '#fff',
        },
        [theme.breakpoints.down('sm')]: {
            width: '70%',
        },
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    activeLink: {
        fontWeight: 'bold',
    },
    langWhite: {
        color: '#fff',
    },
    langBlack: {
        color: '#922d38',
    },
    langBox: {
        borderRight: '1px solid #fff',
    },
    langBoxBlack: {
        borderRight: '1px solid #922d38',
    },
    menuList: {
        // marginRight: 36,
        '& img': {
            width: 28,
            height: 28,
            [theme.breakpoints.down('sm')]: {
                width: 18,
                height: 18,
            },
        },
        '& button': {
            overflow: 'visible',
            [theme.breakpoints.down('sm')]: {
                padding: 0,
                marginLeft: 8,
                borderRadius: 0,
            },
        },
    },
    menuTyp: {
        marginLeft: 24,
    },
    menuTypIn: {
        marginRight: 24,
    },
    // hamburger:{
    //     right: -5,
    // },
    // innerDrawer:{
    //     height: '100vh',
    //     minHeight: 500,
    // },
    // button: {
    //     width: '100%',
    //     minHeight: '60px',
    //     cursor: 'pointer',
    //     borderRadius: '0',
    //     backgroundColor: '#fff',
    //     textAlign: 'left',
    //     marginBottom: 24,
    //     border: 'solid 1px #f4f4f4',
    //     '&:hover': {
    //         backgroundColor: '#fff',
    //     },
    //     '&:active': {
    //         boxShadow: 'none',
    //         backgroundColor: '#fff',
    //     },
    // },
    // fab: {
    //     width: 44,
    //     height: 44,
    //     marginLeft: 16,
    //     backgroundColor: '#fff',
    //     boxShadow: 'none',
    //     border: 'solid 1px #f4f4f4',
    //     '& img': {
    //         height: 18,
    //     },
    //     '&:hover': {
    //         backgroundColor: '#fff',
    //     },
    //     '&:active': {
    //         boxShadow: 'none',
    //         backgroundColor: '#fff',
    //     },
    // },
    // drawerBottom:{
    //     display: 'flex',
    //     justifyContent: 'space-between',
    //     alignItems: 'center',
    //     paddingBottom: 80,
    // }
});

function Navbar(props) {
    const { location, classes } = props;

    const [state, setState] = React.useState({
        atTopBg: true,
        atTopLogo: true,
        open: false,
    });
    // const toggleDrawer = (open) => event => {
    //     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //         return;
    //     }

    //     setState({
    //         ...state,
    //         open
    //     });
    // };

    // Scroll
    function handleScroll() {
        const currentScrollPos = window.pageYOffset;
        const atTopBg = currentScrollPos <= 120;
        const atTopLogo = currentScrollPos <= 80;

        setState({
            ...state,
            atTopBg,
            atTopLogo,
        });
    }

    const { pathname } = location;
    const urlParam = pathname.split('/');

    const lastParam = urlParam[urlParam.length - 1];
    const isHomePage = lastParam === '';
    const whiteColor = isHomePage && state.atTopBg;
    const logoPosition = isHomePage && state.atTopLogo;

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    return (
        <nav className={clsx(classes.nav, logoPosition && classes.homeTopNav)}>
            <div
                className={clsx(
                    classes.background,
                    whiteColor ? classes.transparent : classes.white,
                )}>
                <Container>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={0}>
                        <Grid item xs={12}>
                            <div className={classes.innerNav}>
                                <div className={classes.left}>
                                    <Link to="/">
                                        <img
                                            className={clsx(
                                                whiteColor
                                                    ? classes.logoWhite
                                                    : classes.logoBlack,
                                            )}
                                            src={whiteColor ? Logo : BLogo}
                                            alt="The Moment Logo"
                                        />
                                    </Link>
                                </div>
                                <div className={classes.right}>
                                    <Hidden smDown>
                                        <a
                                            href="/#concept"
                                            className={classes.menuList}>
                                            <Typography
                                                variant="body1"
                                                className={clsx(
                                                    classes.menuTypIn,
                                                    whiteColor
                                                        ? classes.langWhite
                                                        : classes.langBlack,
                                                )}>
                                                Project Concept
                                            </Typography>
                                        </a>
                                        <a
                                            href="/#info"
                                            className={classes.menuList}>
                                            <Typography
                                                variant="body1"
                                                className={clsx(
                                                    classes.menuTypIn,
                                                    whiteColor
                                                        ? classes.langWhite
                                                        : classes.langBlack,
                                                )}>
                                                Project Info
                                            </Typography>
                                        </a>
                                        <a
                                            href="/#plan"
                                            className={classes.menuList}>
                                            <Typography
                                                variant="body1"
                                                className={clsx(
                                                    classes.menuTypIn,
                                                    whiteColor
                                                        ? classes.langWhite
                                                        : classes.langBlack,
                                                )}>
                                                Project Plan
                                            </Typography>
                                        </a>
                                        <a
                                            href="/#gallery"
                                            className={classes.menuList}>
                                            <Typography
                                                variant="body1"
                                                className={clsx(
                                                    classes.menuTypIn,
                                                    whiteColor
                                                        ? classes.langWhite
                                                        : classes.langBlack,
                                                )}>
                                                Gallery
                                            </Typography>
                                        </a>
                                        <a
                                            href="/#location"
                                            className={classes.menuList}>
                                            <Typography
                                                variant="body1"
                                                className={clsx(
                                                    classes.menuTypIn,
                                                    whiteColor
                                                        ? classes.langWhite
                                                        : classes.langBlack,
                                                )}>
                                                Locations
                                            </Typography>
                                        </a>
                                    </Hidden>

                                    <a
                                        href="https://lin.ee/wBWyRon"
                                        className={classes.menuList}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <IconButton
                                            // size="small"
                                            color="inherit">
                                            <img
                                                src={
                                                    whiteColor ? Line : RedLine
                                                }
                                                alt="line icon"
                                            />
                                        </IconButton>
                                    </a>
                                    <a
                                        href="https://facebook.com/themomentcondo/"
                                        className={classes.menuList}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <IconButton
                                            // size="small"
                                            color="inherit">
                                            <img
                                                src={whiteColor ? FB : RedFB}
                                                alt="facebook icon"
                                            />
                                        </IconButton>
                                    </a>
                                    {/* <a href="https://lin.ee/wBWyRon" className={classes.menuList} target="_blank" rel="noopener noreferrer">
                                        <IconButton 
                                            // size="small" 
                                            color="inherit"
                                        >
                                            <img 
                                                alt="instagram icon" 
                                                src={whiteColor ? Instagram : RedIG}
                                            />
                                        </IconButton>
                                    </a> */}
                                    <a
                                        href="tel:+66992656514"
                                        className={classes.menuList}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <Hidden mdUp>
                                            <IconButton
                                                // size="small"
                                                color="inherit">
                                                <img
                                                    alt="mobile icon"
                                                    src={
                                                        whiteColor
                                                            ? Mobile
                                                            : RedMobile
                                                    }
                                                />
                                            </IconButton>
                                        </Hidden>
                                        <Hidden smDown>
                                            <Typography
                                                variant="body1"
                                                className={clsx(
                                                    classes.menuTyp,
                                                    whiteColor
                                                        ? classes.langWhite
                                                        : classes.langBlack,
                                                )}>
                                                099-265-6514
                                            </Typography>
                                        </Hidden>
                                    </a>
                                    {/* </Hidden> */}
                                    {/* <Hidden mdUp>
                                        <IconButton 
                                            size="small" 
                                            className={classes.hamburger}
                                            onClick={toggleDrawer(true)}
                                        >
                                            <img src={whiteColor ? WHBger : HBger} alt="Menu" />
                                        </IconButton>
                                    </Hidden> */}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            {/* <Drawer 
                anchor="right" 
                open={state.open} 
                onClose={toggleDrawer(false)}
                transitionDuration={{ enter: 500, exit: 500 }}
            >
                <Box style={{width: '100vw'}}>
                    <Container maxWidth={false}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            spacing={0}
                        >
                            <Grid item xs={12}>
                                <Box className={classes.innerDrawer}>
                                    <Box>
                                        <Box pt="9px" pb="9px">
                                            <div className={classes.innerNav}>
                                                <div className={classes.left}>
                                                    <Link to={isEn ? "/en" : "/th"}>
                                                        <img src={BLogo} alt="The Moment Logo" />
                                                    </Link>
                                                </div>
                                                <div className={classes.right}>
                                                    <IconButton
                                                        size="small"
                                                        className={classes.hamburger}
                                                        onClick={toggleDrawer(false)}
                                                    >
                                                        <img src={Close} alt="Close" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </Box>
                                        <Box pt="25px">
                                            <Link 
                                                to={isEn ? "/en/#projectConcept" : "/th/#projectConcept"} 
                                                className={classes.link}
                                                onClick={toggleDrawer(false)}
                                            >
                                                <Button 
                                                    variant="outlined" 
                                                    className={classes.button} 
                                                >
                                                    <Typography
                                                        variant="h3"
                                                        color="textSecondary"
                                                    >
                                                        Project Concept
                                                    </Typography>
                                                    <ArrowForwardIcon style={{ marginLeft: 'auto', color: '#d9d2c8' }} />
                                                </Button>
                                            </Link>
                                            <Link
                                                to={isEn ? "/en/#floorplan" : "/th/#floorplan"}
                                                className={classes.link}
                                                onClick={toggleDrawer(false)}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    className={classes.button}
                                                >
                                                    <Typography
                                                        variant="h3"
                                                        color="textSecondary"
                                                    >
                                                        Floor Plan
                                                    </Typography>
                                                    <ArrowForwardIcon style={{ marginLeft: 'auto', color: '#d9d2c8' }} />
                                                </Button>
                                            </Link>
                                            <Link
                                                to={isEn ? "/en/#gallery" : "/th/#gallery"}
                                                className={classes.link}
                                                onClick={toggleDrawer(false)}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    className={classes.button}
                                                >
                                                    <Typography
                                                        variant="h3"
                                                        color="textSecondary"
                                                    >
                                                        Facility
                                                    </Typography>
                                                    <ArrowForwardIcon style={{ marginLeft: 'auto', color: '#d9d2c8' }} />
                                                </Button>
                                            </Link>
                                            <Link
                                                to={isEn ? "/en/#contactus" : "/th/#contactus"}
                                                className={classes.link}
                                                onClick={toggleDrawer(false)}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    className={classes.button}
                                                >
                                                    <Typography
                                                        variant="h3"
                                                        color="textSecondary"
                                                    >
                                                        Location
                                                    </Typography>
                                                    <ArrowForwardIcon style={{ marginLeft: 'auto', color: '#d9d2c8' }} />
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Box>
                                    <Box pb="16px" className={classes.drawerBottom}>
                                        <div>
                                            <Box display="inline-block" pr="6px" className={clsx(whiteColor ? classes.langBox : classes.langBoxBlack)}>
                                                <Link to="/en" className={classes.link}>
                                                    <Typography
                                                        variant="body1"
                                                        className={clsx(classes.langBlack,isEn && classes.activeLink)}
                                                    >
                                                        EN
                                                    </Typography>
                                                </Link>
                                            </Box>
                                            <Box display="inline-block" pl="6px">
                                                <Link to="/th" className={classes.link}>
                                                    <Typography
                                                        variant="body1"
                                                        className={clsx(classes.langBlack,!isEn && classes.activeLink)}
                                                    >
                                                        TH
                                                    </Typography>
                                                </Link>
                                            </Box>
                                        </div>
                                        <div>
                                            <a 
                                                className={classes.link} 
                                                href="tel:+66621429797"
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                onClick={toggleDrawer(false)}
                                            >
                                                <Fab className={classes.fab}>
                                                    <img src={Mobile} alt="Tel" />
                                                </Fab>
                                            </a>
                                            <a 
                                                className={classes.link} 
                                                href="https://www.facebook.com/Holmeekkamai22/"
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                onClick={toggleDrawer(false)}
                                            >
                                                <Fab className={classes.fab}>
                                                    <img src={FB} alt="Facebook" />
                                                </Fab>
                                            </a>
                                            <a
                                                className={classes.link}
                                                href="https://www.instagram.com/holmeekkamai22/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={toggleDrawer(false)}
                                            >
                                                <Fab className={classes.fab}>
                                                    <img src={IG} alt="Instargram" />
                                                </Fab>
                                            </a>
                                        </div>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Drawer> */}
        </nav>
    );
}

Navbar.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }),
};

Navbar.defaultProps = {
    location: PropTypes.shape({
        pathname: '/',
    }),
};

export default withRouter(withStyles(styles)(Navbar));
