import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import configureStore from './store';

import * as serviceWorker from './serviceWorker';
import App from './app';
import './index.css';

const gtmId1 = 'GTM-K4T838K';
TagManager.initialize({ gtmId: gtmId1 });

const gtmId2 = 'GTM-M67Z726S';
TagManager.initialize({ gtmId: gtmId2 });

ReactDOM.render(
    <Provider store={configureStore()}>
        <App />
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
