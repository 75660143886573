export default {
    th: {
        title: 'ลงทะเบียน',
        firstname: 'ชื่อ',
        fnameError: 'กรุณากรอกชื่อ',
        lastname: 'นามสกุล',
        lnameError: 'กรุณากรอกนามสกุล',
        email: 'อีเมล',
        emailError: 'กรุณากรอกอีเมล',
        mobile: 'เบอร์โทรศัพท์',
        mobileError: 'กรุณากรอกเบอร์โทรศัพท์',
        budget: 'งบประมาณ',
        budgetError: 'กรุณาเลือกงบประมาณ',
        roomSizeError: 'กรุณาเลือกขนาดห้อง',
        button: 'ลงทะเบียน',
        thankyou:
            'ขอบคุณสำหรับการลงทะเบียน ทางเจ้าหน้าที่จะติดต่อเพื่อให้ข้อมูลกับท่านโดยเร็วที่สุด',
        seemap: 'ดูแผนที่',
        gohome: 'กลับหน้าแรก',
        register: 'เริ่ม 130,000 บาทต่อตร.ม. ลงทะเบียนรับสิทธิพิเศษ',
        select: [
            '2.69-3 ล้านบาท',
            '3.01-3.5 ล้านบาท',
            '3.51-4 ล้านบาท',
            '4 ล้านบาทขึ้นไป',
        ],
        room: ['Studio', '1-Bedroom', '1-Bedroom Plus'],
    },
    en: {
        title: 'REGISTER',
        firstname: 'First Name',
        fnameError: 'Please fill your first name',
        lastname: 'Last Name',
        lnameError: 'Please fill your last name',
        email: 'Email',
        emailError: 'Please fill your email',
        mobile: 'Phone',
        mobileError: 'Please fill your phone number',
        budget: 'Budget',
        budgetError: 'Please select your budget',
        button: 'Submit',
        thankyou:
            'Thank you for your interest in our project. Our sale team will contact you shortly.',
        seemap: 'See map',
        gohome: 'Go back to home page',
        register:
            'Starts 130,000 baht per square meter. Register to receive special privileges.',
        select: ['4-7 MB', '8-10 MB', '11-14 MB', '> 15 MB'],
    },
};
