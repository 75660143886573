import {
    UPDATE_FOOTER
} from 'actions/fixFooter';

const initialState = {
    seen: false,
};

const fixFooterReducer = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_FOOTER:
        return {
            ...state,
            seen: action.value
        };
    default:
        return state;
    }
};
export default fixFooterReducer;