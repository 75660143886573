/* eslint-disable react/forbid-prop-types */
import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import isNumeric from 'validator/lib/isNumeric';
import { BeatLoader } from 'react-spinners';
import { withStyles } from '@material-ui/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import _ from 'lodash';
import {
    errorGetInTouch,
    updateGetInTouch,
    postCreate,
} from 'actions/register';
import LangText from 'common/lang';
import FbButton from '../button';

const styles = theme => ({
    outer: {
        width: 417,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    form: {
        width: '100%',
        margin: '0 0 30px 0',
        [theme.breakpoints.down('sm')]: {
            margin: '0 0 24px 0',
        },
        // '& label.Mui-focused': {
        //     fontWeight: 'normal',
        //     fontStyle: 'normal',
        //     fontStretch: 'normal',
        //     lineHeight: 1,
        //     letterSpacing: 'normal',
        //     "@media screen and (max-width: 961px)": {
        //         fontSize: "0.875rem",
        //     }
        // },
        '& .MuiInput-underline': {
            borderBottomColor: '#82bad1',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#82bad1',
            transform: 'none',
        },
        '& label': {
            width: '100%',
        },
        '& input': {
            color: '#82bad1',
            fontSize: 24,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.33,
            letterSpacing: 'normal',
            '@media screen and (max-width: 961px)': {
                fontSize: '1.25rem',
                lineHeight: 1.4,
            },
        },
        '& .MuiFormLabel-root': {
            color: '#82bad1',
        },
        '& .MuiFormHelperText-root': {
            fontSize: 18,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1,
            letterSpacing: 'normal',
            color: '#fff',
            '@media screen and (max-width: 961px)': {
                fontSize: '0.75rem',
            },
        },
    },
    formName: {
        maxWidth: 'calc(50% - 24px)',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
    topForm: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    bottomForm: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    selectForm: {
        maxWidth: 'calc(50% - 24px)',
        width: '100%',
        margin: '0 0 30px 0',
        [theme.breakpoints.down('sm')]: {
            margin: '0 0 24px 0',
            maxWidth: '100%',
        },
        '& .MuiFormLabel-root': {
            color: '#82bad1',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#82bad1',
            transform: 'none',
        },
        '& .MuiSelect-select': {
            height: 32,
            fontSize: 24,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.33,
            letterSpacing: 'normal',
            '@media screen and (max-width: 961px)': {
                height: 24,
                fontSize: '1.25rem',
                lineHeight: 1.4,
            },
        },
        '& .MuiSelect-icon': {
            color: '#82bad1',
        },
        '& #select-budget': {
            fontSize: 18,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1,
            letterSpacing: 'normal',
            color: '#fff',
            '@media screen and (max-width: 961px)': {
                fontSize: '0.75rem',
            },
        },
        '& #budget': {
            fontSize: '1.5rem',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.33,
            letterSpacing: 'normal',
            '@media screen and (max-width: 961px)': {
                fontSize: '1.25rem',
                lineHeight: 1.4,
            },
        },
    },
    selectList: {
        fontSize: '1.5rem',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
        '@media screen and (max-width: 961px)': {
            fontSize: '1.25rem',
            lineHeight: 1.4,
        },
    },
});

function GetInTouch(props) {
    const {
        classes,
        loading,
        data,
        error,
        postData,
        updateData,
        errorData,
        location,
        // closeGetInTouch,
    } = props;

    const { search } = location;
    const parsed = queryString.parse(search);

    const selectList = LangText.th.select;
    const roomList = LangText.th.room;

    const handleChange = name => event => {
        updateData(name, event.target.value);
    };

    const handleSelect = name => event => {
        updateData(name, event.target.value);
    };

    const submitForm = () => {
        const inputData = {
            name: data.name,
            lname: data.lname,
            email: data.email,
            mobile: data.mobile,
            budget: data.budget,
            roomSize: data.roomSize,
            source: parsed.utm_source ? parsed.utm_source : '-',
            medium: parsed.utm_medium ? parsed.utm_medium : '-',
            campaignName: parsed.utm_campaign ? parsed.utm_campaign : '-',
            term: parsed.utm_term ? parsed.utm_term : '-',
            content: parsed.utm_content ? parsed.utm_content : '-',
        };

        // validation before call redux action
        const errorText = {
            name: isEmpty(data.name) ? 'กรุณากรอกชื่อ' : '',
            lname: isEmpty(data.lname) ? 'กรุณากรอกนามสกุล' : '',
            email:
                !isEmail(data.email) || isEmpty(data.email)
                    ? 'กรุณากรอกอีเมล'
                    : '',
            mobile: isEmpty(data.mobile) ? 'กรุณากรอกเบอร์โทรศัพท์' : '',
            budget: isEmpty(data.budget) ? 'กรุณาเลือกงบประมาณ' : '',
            roomSize: isEmpty(data.roomSize) ? 'กรุณาเลือกขนาดห้อง' : '',
        };
        if (!isNumeric(data.mobile) || data.mobile.length < 9) {
            errorText.mobile = 'กรุณากรอกข้อมูลให้ถูกต้อง';
        }

        const emptyError = _.values(errorText).every(_.isEmpty);
        if (emptyError) {
            postData(inputData);
        } else {
            errorData(errorText);
        }
    };

    return (
        <form className={classes.outer}>
            <Box className={classes.topForm}>
                <TextField
                    id="name"
                    label="ชื่อ"
                    className={clsx(classes.form, classes.formName)}
                    value={data.name}
                    onChange={handleChange('name')}
                    helperText={error.name}
                />
                <TextField
                    id="name"
                    label="นามสกุล"
                    className={clsx(classes.form, classes.formName)}
                    value={data.lname}
                    onChange={handleChange('lname')}
                    helperText={error.lname}
                />
            </Box>
            <TextField
                label="เบอร์โทรศัพท์"
                className={classes.form}
                value={data.mobile}
                onChange={handleChange('mobile')}
                helperText={error.mobile}
                type="number"
            />
            <TextField
                label="อีเมล"
                className={classes.form}
                value={data.email}
                onChange={handleChange('email')}
                margin="normal"
                helperText={error.email}
                type="email"
            />
            <Box className={classes.bottomForm}>
                <FormControl className={classes.selectForm}>
                    <InputLabel htmlFor="budget">งบประมาณ (บาท)</InputLabel>
                    <Select
                        value={data.budget}
                        onChange={handleSelect('budget')}
                        IconComponent={ArrowDownwardIcon}
                        inputProps={{
                            name: 'budget',
                            id: 'budget',
                        }}>
                        {selectList.map(item => (
                            <MenuItem
                                className={classes.selectList}
                                key={item}
                                value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                    {error.budget && (
                        <Box mt="8px">
                            <Typography variant="caption" color="error">
                                {error.budget}
                            </Typography>
                        </Box>
                    )}
                </FormControl>
                <FormControl className={classes.selectForm}>
                    <InputLabel htmlFor="budget">ขนาดห้องที่สนใจ</InputLabel>
                    <Select
                        value={data.roomSize}
                        onChange={handleSelect('roomSize')}
                        IconComponent={ArrowDownwardIcon}
                        inputProps={{
                            name: 'roomSize',
                            id: 'roomSize',
                        }}>
                        {roomList.map(item => (
                            <MenuItem
                                className={classes.selectList}
                                key={item}
                                value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                    {error.budget && (
                        <Box mt="8px">
                            <Typography variant="caption" color="error">
                                {error.roomSize}
                            </Typography>
                        </Box>
                    )}
                </FormControl>
            </Box>
            <Box pt={4} pb={{ xs: 4, md: 13 }}>
                {!loading ? (
                    <FbButton color="primary" onClick={submitForm}>
                        ลงทะเบียนรับสิทธิพิเศษ
                    </FbButton>
                ) : (
                    <FbButton color="primary">
                        <BeatLoader sizeUnit="px" size={15} color="#922d38" />
                    </FbButton>
                )}
            </Box>
        </form>
    );
}

GetInTouch.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    data: PropTypes.shape({
        name: PropTypes.string.isRequired,
        lname: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        mobile: PropTypes.string.isRequired,
        budget: PropTypes.string.isRequired,
        roomSize: PropTypes.string.isRequired,
    }).isRequired,
    error: PropTypes.shape({
        name: PropTypes.string.isRequired,
        lname: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        mobile: PropTypes.string.isRequired,
        budget: PropTypes.string.isRequired,
        roomSize: PropTypes.string.isRequired,
    }).isRequired,
    postData: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
    errorData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    loading: state.register.loading,
    data: state.register.data,
    error: state.register.error,
    isValid: state.register.isValid,
});

const mapDispatchToProps = {
    errorData: errorGetInTouch,
    updateData: updateGetInTouch,
    postData: postCreate,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(withStyles(styles)(GetInTouch)));
