import firebase from 'common/firebase';

// Action
// -- CREATE/UPDATE
export const FETCH_ABOUT = 'FETCH_ABOUT';
export const FETCH_ABOUT_DONE = 'FETCH_ABOUT_DONE';
export const FETCH_ABOUT_FAIL = 'FETCH_ABOUT_FAIL';

// Sync Action Creator
export function fetch() {
    return {
        type: FETCH_ABOUT,
    };
};
export function fetchDone(data) {
    return {
        type: FETCH_ABOUT_DONE,
        data,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_ABOUT_FAIL,
        message,
    };
};

// Async Action Creator
export function getData(lang) {
    return async (dispatch) => {
        dispatch(fetch());

        const ref = firebase.firestore().collection("pages").doc("aboutus");
        try {
            const result = await ref.get();
            const output = {
                img: result.data().img,
                desc: result.data().desc[lang],
            };
            dispatch(fetchDone(output));
        }
        catch (error) {
            dispatch(fetchFail(error.message));
        }
    };
}
