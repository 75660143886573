import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import {
    Thankyou,
} from 'views';
// import About from 'containers/aboutus';
// import Progress from 'containers/progress';
import Home from 'containers/home';
import {
    RouteWithLayout,
    WhiteLayout,
    BlackLayout,
} from 'layouts';

const Routes = () => {
    return (
        <Switch>
            <RouteWithLayout exact path="/" layout={WhiteLayout} component={Home} />
            <RouteWithLayout exact path="/thankyou" layout={BlackLayout} component={Thankyou} />
            {/* <RouteWithLayout exact path="/aboutus" layout={BlackLayout} component={About} /> */}
            {/* <RouteWithLayout exact path="/progress" layout={BlackLayout} component={Progress} /> */}
            <Redirect to="/" />
        </Switch>
    );
};

export default Routes;
