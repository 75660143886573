/* eslint-disable react/forbid-prop-types */
import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/styles';
import TopCurve from './curveTop.svg';
import BottomCurve from './curveBottom.svg';
import MobileBottom from './mobileBottom.svg';
import MobileTop from './topMobile.svg';

// Icons
import StylishHallLobby from './icons/StylishHallLobby.svg';
import SynergyGym from './icons/SynergyGym.svg';
import StartupSpace from './icons/StartupSpace.svg';
import SmartLocker from './icons/SmartLocker.svg';
import SecretGarden from './icons/SecretGarden.svg';
import SkyLabPoolWithJacuzzi from './icons/SkyLabPoolWithJacuzziAndSunnyKidsPool.svg';
import SunsetLounge from './icons/SunsetLounge.svg';
import ScenicBBQStation from './icons/ScenicBBQStation.svg';
import SteppingYard from './icons/SteppingYard.svg';
import StarryRooftopTheater from './icons/StarryRooftopTheater.svg';
import SecurityGuardandCCTV247 from './icons/SecurityGuardandCCTV24_7.svg';
import SecurityAccessCard from './icons/SecurityAccessCard.svg';

const styles = theme => ({
    root: {
        height: 'auto',
        display: 'block',
        overscrollBehaviorX: 'contain',
        maxWidth: '100%',
        overflowX: 'hidden',
    },
    rootOne: {
        backgroundColor: '#922d38',
        height: 'auto',
        display: 'block',
        paddingTop: 8,
        paddingBottom: 63,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 40,
            paddingBottom: 40,
        },
    },
    desc: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    item: {
        width: '100%',
        border: 'solid 2px #fff',
        height: 0,
        borderRadius: 20,
        paddingBottom: '100%',
        position: 'relative',
        borderBottomLeftRadius: 0,
        [theme.breakpoints.down('sm')]: {
            border: 'solid 1px #fff',
        },
    },
    itemInner: {
        position: ' absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        color: 'white',
        padding: '8px 16px 8px 16px',
        [theme.breakpoints.down('sm')]: {
            padding: 8,
        },
        '& h6':{
            lineHeight: 1,
            [theme.breakpoints.down('md')]: {
                fontSize: 18,
            },
        },
        '& img': {
            maxHeight: 85,
            maxWidth: 85,
            marginLeft: '-8px',
            filter:
                'invert(100%) sepia(100%) saturate(2%) hue-rotate(322deg) brightness(102%) contrast(101%)',
            [theme.breakpoints.down('md')]: {
                maxHeight: 63,
                maxWidth: 63,
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '-4px',
                maxHeight: 48,
                maxWidth: 48,
            },
        },
    },
    rootZero: {
        backgroundColor: '#f3f2ec',
        position: 'relative',
        '& img': {
            position: 'relative',
            width: '100%',
            // height: 155,
            bottom: '-1px',
            display: 'block',
            // position: 'absolute',
        },
    },
    rootTwo: {
        backgroundColor: '#f3f2ec',
        position: 'relative',
        '& img': {
            position: 'relative',
            width: '100%',
            // height: 155,
            top: '-1px',
            display: 'block',
            // position: 'absolute',
        },
    },
});

function Concept(props) {
    const { classes } = props;

    return (
        <div className={classes.root}>
            <Hidden smDown>
                <div className={classes.rootZero}>
                    <img src={TopCurve} alt="facility" />
                </div>
            </Hidden>
            <Hidden mdUp>
                <div className={classes.rootZero}>
                    <img src={MobileTop} alt="facility" />
                </div>
            </Hidden>
            <div className={classes.rootOne} id="projectConcept">
                {/* Desktop */}
                <Container>
                    <Grid
                        spacing={6}
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start">
                        <Grid item xs={10}>
                            <Box
                                pb={{ xs: 3, md: 4 }}
                                style={{ textAlign: 'center' }}>
                                <Typography
                                    variant="h2"
                                    style={{ color: '#fff' }}>
                                    FACILITIES
                                </Typography>
                            </Box>
                            <Box style={{ textAlign: 'center' }} mb={5}>
                                <Typography
                                    variant="body1"
                                    className={classes.desc}
                                    style={{ color: '#fff' }}>
                                    ครบครันสิ่งอำนวยความสะดวกทันสมัยในความเงียบสงบ
                                    ปลอดภัยเป็นส่วนตัวท่ามกลางบรรยากาศธรรมชาติร่มรื่น
                                    สดชื่น เชื่อมต่อภายในโครงการจากชั้น Ground
                                    สู่ Rooftop
                                    ตื่นตากับทัศนียภาพความสวยงามยามเช้าจรดเย็นกับสวนลอยฟ้า
                                    East & West Side Sky Atmosphere
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Hidden smDown>
                    <Container>
                        <Grid
                            spacing={6}
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start">
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img
                                            src={StylishHallLobby}
                                            alt="icon"
                                        />
                                        <Typography
                                            variant="subtitle1"
                                            style={{ color: '#fff' }}>
                                            Stylish Hall Lobby
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img src={SynergyGym} alt="icon" />
                                        <Typography
                                            variant="subtitle1"
                                            style={{ color: '#fff' }}>
                                            Synergy Gym
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img src={StartupSpace} alt="icon" />
                                        <Typography
                                            variant="subtitle1"
                                            style={{ color: '#fff' }}>
                                            Startup Space
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img src={SmartLocker} alt="icon" />
                                        <Typography
                                            variant="subtitle1"
                                            style={{ color: '#fff' }}>
                                            Smart Locker
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img src={SecretGarden} alt="icon" />
                                        <Typography
                                            variant="subtitle1"
                                            style={{ color: '#fff' }}>
                                            Secret Garden
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img
                                            src={SkyLabPoolWithJacuzzi}
                                            alt="icon"
                                        />
                                        <Typography
                                            variant="subtitle1"
                                            style={{ color: '#fff' }}>
                                            Sky Lab Pool With Jacuzzi
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img src={SunsetLounge} alt="icon" />
                                        <Typography
                                            variant="subtitle1"
                                            style={{ color: '#fff' }}>
                                            Sunset Lounge
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img
                                            src={ScenicBBQStation}
                                            alt="icon"
                                        />
                                        <Typography
                                            variant="subtitle1"
                                            style={{ color: '#fff' }}>
                                            Scenic BBQ Station
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img src={SteppingYard} alt="icon" />
                                        <Typography
                                            variant="subtitle1"
                                            style={{ color: '#fff' }}>
                                            Stepping Yard
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img
                                            src={StarryRooftopTheater}
                                            alt="icon"
                                        />
                                        <Typography
                                            variant="subtitle1"
                                            style={{ color: '#fff' }}>
                                            Starry Rooftop Theater
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img
                                            src={SecurityGuardandCCTV247}
                                            alt="icon"
                                        />
                                        <Typography
                                            variant="subtitle1"
                                            style={{ color: '#fff' }}>
                                            Security Guard and CCTV 24/7
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img
                                            src={SecurityAccessCard}
                                            alt="icon"
                                        />
                                        <Typography
                                            variant="subtitle1"
                                            style={{ color: '#fff' }}>
                                            Security Access Card
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Hidden>
                <Hidden mdUp>
                    <Container>
                        <Grid
                            spacing={2}
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start">
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img
                                            src={StylishHallLobby}
                                            alt="icon"
                                        />
                                        <Typography
                                            variant="body2"
                                            style={{ color: '#fff' }}>
                                            Stylish Hall Lobby
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img src={SynergyGym} alt="icon" />
                                        <Typography
                                            variant="body2"
                                            style={{ color: '#fff' }}>
                                            Synergy Gym
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img src={StartupSpace} alt="icon" />
                                        <Typography
                                            variant="body2"
                                            style={{ color: '#fff' }}>
                                            Startup Space
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img src={SmartLocker} alt="icon" />
                                        <Typography
                                            variant="body2"
                                            style={{ color: '#fff' }}>
                                            Smart Locker
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img src={SecretGarden} alt="icon" />
                                        <Typography
                                            variant="body2"
                                            style={{ color: '#fff' }}>
                                            Secret Garden
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img
                                            src={SkyLabPoolWithJacuzzi}
                                            alt="icon"
                                        />
                                        <Typography
                                            variant="body2"
                                            style={{ color: '#fff' }}>
                                            Sky Lab Pool With Jacuzzi
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img src={SunsetLounge} alt="icon" />
                                        <Typography
                                            variant="body2"
                                            style={{ color: '#fff' }}>
                                            Sunset Lounge
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img
                                            src={ScenicBBQStation}
                                            alt="icon"
                                        />
                                        <Typography
                                            variant="body2"
                                            style={{ color: '#fff' }}>
                                            Scenic BBQ Station
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img src={SteppingYard} alt="icon" />
                                        <Typography
                                            variant="body2"
                                            style={{ color: '#fff' }}>
                                            Stepping Yard
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img
                                            src={StarryRooftopTheater}
                                            alt="icon"
                                        />
                                        <Typography
                                            variant="body2"
                                            style={{ color: '#fff' }}>
                                            Starry Rooftop Theater
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img
                                            src={SecurityGuardandCCTV247}
                                            alt="icon"
                                        />
                                        <Typography
                                            variant="body2"
                                            style={{ color: '#fff' }}>
                                            Security Guard and CCTV 24/7
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box className={classes.item}>
                                    <Box className={classes.itemInner}>
                                        <img
                                            src={SecurityAccessCard}
                                            alt="icon"
                                        />
                                        <Typography
                                            variant="body2"
                                            style={{ color: '#fff' }}>
                                            Security Access Card
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Hidden>
            </div>
            <Hidden smDown>
                <div className={classes.rootTwo}>
                    <img src={BottomCurve} alt="facility" />
                </div>
            </Hidden>
            <Hidden mdUp>
                <div className={classes.rootTwo}>
                    <img src={MobileBottom} alt="facility" />
                </div>
            </Hidden>
        </div>
    );
}

Concept.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Concept);
