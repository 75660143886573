/* eslint-disable react/forbid-prop-types */
import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/styles';
import BigCircularProgress from 'components/simple/progress/bigCircle';
import CircularProgress from 'components/simple/progress/circle';
import BlueCurve from './curve.svg';
import Bottom from './bottom.svg';

const styles = theme => ({
    root: {
        height: 'auto',
        display: 'block',
        overscrollBehaviorX: 'contain',
        maxWidth: '100%',
        overflowX: 'hidden',
    },
    rootOne: {
        backgroundColor: '#82bad1',
        height: 'auto',
        display: 'block',
        paddingTop: 120,
        paddingBottom: 80,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 48,
            paddingBottom: 0,
        },
    },
    title: {
        padding: '8px 0 8px 20px',
        borderLeft: 'solid 3px #922d38',
        [theme.breakpoints.down('sm')]: {
            padding: '8px 0 8px 16px',
        },
    },
    media: {
        height: 400,
        width: 'auto',
        backgroundSize: 'contain',
        [theme.breakpoints.down('sm')]: {
            height: 246,
        },
    },
    percent: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-evenly',
        },
    },
    rootTwo: {
        backgroundColor: '#202b4f',
        marginBottom: 8,
        position: 'relative',
        '& img': {
            width: '100%',
            // height: 155,
            top: '-1px',
            position: 'relative',
        },
    },
    rootThree: {
        backgroundColor: '#82bad1',
        marginBottom: 8,
        position: 'relative',
        '& img': {
            width: '100%',
            // height: 155,
            bottom: '-1px',
            position: 'relative',
        },
    },
});

function Materials(props) {
    const { classes, data } = props;

    return (
        <div className={classes.root}>
            <div className={classes.rootOne}>
                {/* Desktop */}
                <Container>
                    <Grid
                        spacing={6}
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start">
                        <Grid item xs={12}>
                            <Box
                                mb={{ xs: 2, md: '5px' }}
                                className={classes.title}>
                                <Typography variant="h2" color="secondary">
                                    PROJECT PROGRESS
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            {data.img && (
                                <CardMedia
                                    className={classes.media}
                                    image={data.img}
                                    title="Project Progress"
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Box pb={6}>
                                <Typography
                                    variant="body1"
                                    color="textSecondary">
                                    {data.desc}
                                </Typography>
                            </Box>
                            <Box className={classes.percent}>
                                <BigCircularProgress
                                    value={Number(data.allPercent)}
                                    title="ภาพรวมการก่อสร้าง"
                                />
                                <CircularProgress
                                    value={Number(data.strPercent)}
                                    title="งานโครงสร้าง"
                                />
                                <CircularProgress
                                    value={Number(data.archPercent)}
                                    title="งานสถาปัตยกรรม"
                                />
                                <CircularProgress
                                    value={Number(data.sysPercent)}
                                    title="งานระบบวิศวะกรรม"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Hidden smDown>
                <div className={classes.rootTwo}>
                    <img src={BlueCurve} alt="project progress" />
                </div>
            </Hidden>
            <Hidden mdUp>
                <div className={classes.rootThree}>
                    <img src={Bottom} alt="project progress" />
                </div>
            </Hidden>
        </div>
    );
}

Materials.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default withStyles(styles)(Materials);
