/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const styles = theme => ({
    root: {
        height: 'auto',
        display: 'block',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            margin: '0 12px 48px 12px',
        },
    },
    circle: {
        width: 135,
        color: '#922d38',
        '& .CircularProgressbar-trail': {
            stroke: '#f3f2ec',
        },
        '& .CircularProgressbar-path': {
            stroke: '#922d38',
        },
        '& text.CircularProgressbar-text': {
            fill: '#ffffff',
            fontFamily: 'DB Heavent',
            fontSize: 32,
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1,
            letterSpacing: 'normal',
        },
    },
});

function Materials(props) {
    const { classes, value, title } = props;

    return (
        <div className={classes.root}>
            <CircularProgressbar
                className={classes.circle}
                value={value}
                strokeWidth={3}
                text={`${value}%`}
            />
            <Box mt={3}>
                <Typography color="textSecondary" variant="body1">
                    {title}
                </Typography>
            </Box>
        </div>
    );
}

Materials.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
};

export default withStyles(styles)(Materials);
