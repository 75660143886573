import moment from 'moment';
import firebase from 'common/firebase';
// import { showSnack } from './main';

export const ADD_DOWNLOAD = 'ADD_DOWNLOAD';
export const UPDATE_DOWNLOAD = 'UPDATE_DOWNLOAD';
export const ERROR_DOWNLOAD = 'ERROR_DOWNLOAD';
export const CLOSE_DOWNLOAD = 'CLOSE_DOWNLOAD';
export const RENDER_DOWNLOAD = 'RENDER_DOWNLOAD';
export function updateDownload(newValue) {
    return {
        type: UPDATE_DOWNLOAD,
        newValue
    };
}
export function errorDownload(text) {
    return {
        type: ERROR_DOWNLOAD,
        error: text
    };
}
export function addDownload() {
    return {
        type: ADD_DOWNLOAD,
    };
}
export function renderDownload() {
    return {
        type: RENDER_DOWNLOAD,
    };
}
export function closeDownload() {
    return {
        type: CLOSE_DOWNLOAD
    };
};

export function postCreate(data) {
    return (dispatch) => {
        dispatch(addDownload());

        const ref = firebase.firestore().collection("downloads");
        const inputData = {
            ...data,
            timestamp: moment().valueOf(),
        };

        return ref.add(inputData).then(() => {
            dispatch(renderDownload());
            // dispatch(showSnack('success', 'Tag created successfully.'));
        }).catch((err) => {
            console.log(err);
        });
    };
}
