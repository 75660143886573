/* eslint-disable react/forbid-prop-types */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Slider from 'react-slick';
import { withStyles } from '@material-ui/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import GetInTouch from 'components/simple/getInTouch';
import CurveSVG from './curve.svg';
import CurveVTC from './curveVtc.svg';
import CurveTop from './curveTop.svg';

const styles = theme => ({
    root: {
        height: 'auto',
        display: 'block',
        backgroundColor: '#922d38',
        overscrollBehaviorX: 'contain',
        maxWidth: '100%',
        overflowX: 'hidden',
    },
    caption: {
        margin: '94px 0 83px 0',
        [theme.breakpoints.down('sm')]: {
            margin: '80px 0 32px 0',
        },
    },
    title: {
        padding: '8px 0 8px 20px',
        borderLeft: 'solid 3px #82bad1',
        [theme.breakpoints.down('sm')]: {
            padding: '8px 0 8px 16px',
        },
    },
    curve: {
        backgroundColor: '#f3f2ec',
        '& img': {
            position: 'relative',
            width: '100%',
            top: '-1px',
        },
    },
    outerSlide: {
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            height: '100vw',
        },
    },
    imageCurve: {
        position: 'absolute',
        top: 0,
        right: '-1px',
        height: '100%',
    },
    imageCurveTop: {
        position: 'absolute',
        bottom: '-1px',
        width: '100%',
    },
    slick: {
        position: 'absolute',
        // position: 'relative',
        width: '100%',
        height: '100%',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            height: '100vw',
        },
        '& .slick-track': {
            display: 'flex',
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                height: '100vw',
            },
        },
        '& .slick-list': {
            position: 'relative',
            // position: 'absolute',
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                height: '100vw',
            },
            '& .slick-slide': {
                height: '100%',
                [theme.breakpoints.down('sm')]: {
                    height: '100vw',
                },
                '& div': {
                    height: '100%',
                    [theme.breakpoints.down('sm')]: {
                        height: '100vw',
                    },
                },
            },
        },
        '& .slick-dots': {
            listStyleType: 'none',
            position: 'absolute',
            bottom: 24,
            margin: '0 auto',
            padding: '0px 20px',
            width: '100%',
            boxSizing: 'border-box',
            textAlign: 'center',
            '& li': {
                display: 'inline-block',
                margin: 6,
                [theme.breakpoints.down('sm')]: {
                    margin: 4,
                },
                '& div': {
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    opacity: 0.5,
                    backgroundColor: '#ffffff',
                    [theme.breakpoints.down('sm')]: {
                        width: 6,
                        height: 6,
                    },
                },
            },
            '& li.slick-active': {
                '& div': {
                    opacity: 1,
                },
            },
        },
    },
    slide: {
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '100vw',
        },
    },
    boxRegister:{
        maxWidth: 417,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    }
});

function Contact(props) {
    const { classes, data } = props;

    const settings = {
        customPaging() {
            return <div></div>;
        },
        dots: true,
        fade: true,
        swipeToSlide: true,
        lazyLoad: true,
        infinite: true,
        speed: 700,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    return (
        <div className={classes.root} id="register">
            <Grid
                spacing={0}
                justify="space-between"
                container
                direction="row"
                alignItems="stretch">
                <Grid item xs={12} md={7}>
                    <Box className={classes.outerSlide}>
                        <Slider {...settings} className={classes.slick}>
                            {data.map(item => (
                                <CardMedia
                                    key={item.order}
                                    className={classes.slide}
                                    image={item.img}
                                    component="div"
                                />
                            ))}
                        </Slider>
                        <Hidden smDown>
                            <img
                                src={CurveVTC}
                                alt="The Moment"
                                className={classes.imageCurve}
                            />
                        </Hidden>
                        <Hidden mdUp>
                            <img
                                src={CurveTop}
                                alt="The Moment"
                                className={classes.imageCurveTop}
                            />
                        </Hidden>
                    </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Box pl={{ xs: 2, md: 0 }} pr={{ xs: 2, md: 0 }}>
                        <Box pt={{ xs: 0, md: 18 }} pb={{ xs: 4, md: 3 }}>
                            <Box className={classes.title}>
                                <Typography variant="h2" color="primary">
                                    REGISTER
                                </Typography>
                            </Box>
                        </Box>
                        <Box pb={{ xs: 4, md: 3 }} className={classes.boxRegister}>
                            <Typography
                                variant="subtitle1"
                                style={{ color: '#fff' }}>
                                The Moment รัชดา 19 คอนโดใหม่ พร้อมอยู่
                            </Typography>
                            <Typography variant="h3" style={{ color: '#fff' }}>
                                ใกล้ MRT รัชดา เป็นส่วนตัวเพียง 134 ยูนิต
                                <br />
                                1 ห้องนอนใหญ่ เฟอร์ครบ* เริ่ม 2.69 ล้าน*
                            </Typography>
                        </Box>
                        <GetInTouch />
                    </Box>
                    <Hidden mdUp>
                        <Box className={classes.curve}>
                            <img src={CurveSVG} alt="The Moment" />
                        </Box>
                    </Hidden>
                </Grid>
            </Grid>
        </div>
    );
}

Contact.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
};

export default withStyles(styles)(Contact);
