import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const styles = theme => ({
    root: {
        position: 'relative',
        backgroundColor: '#fff',
        width: '100%',
        height: 'auto',
        textAlign: 'left',
        padding: '128px 0 124px 0',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            padding: '50px 0 62px 0',
            textAlign: 'center',
        }
    },
    box:{
        height: '100%',
        overflow: 'hidden',
    },
    card: {
        borderRadius: 0,
        boxShadow: 'none',
        marginBottom: 64,
    },
    media:{
        height:'100%',
        [theme.breakpoints.down('sm')]: {
            height: 246,
        }
    },
    content:{
        backgroundColor: "#f4f4f4",
        height: '100%',
        padding: '96px 50px 58px 50px !important',
        [theme.breakpoints.down('sm')]: {
            padding: '64px 16px !important',
        }
    },
});

function Thankyou(props) {
    const {
        classes,
        location,
        data,
        getData,
    } = props;

    const { pathname } = location;
    const lang = pathname.split("/")[1];
    
    React.useEffect(() => {
        getData(lang);
    }, [getData, lang]);

    return (
        <div className={classes.root}>
            <Container maxWidth={false}>
                <Grid
                    spacing={0}
                    container
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                >
                    <Grid item xs={12} md={8}>
                        <Box className={classes.box}>
                            {
                                data.img &&
                                <CardMedia
                                    className={classes.media}
                                    component="img"
                                    alt="Thank you"
                                    image={data.img}
                                    title="Thank you"
                                />
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box className={classes.box}>
                            <CardContent className={classes.content}>
                                <Box pb={{ xs: "24px", md:"48px"}}>
                                    <Typography variant="h2" color="textPrimary">
                                        PROJECT PROGRESS
                                    </Typography>
                                </Box>
                                <Box pb={{ xs: "24px", md: "48px" }}>
                                    <Typography variant="h3" color="textPrimary">
                                        {data.percent}% COMPLETE
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="body1" color="textSecondary">
                                        {data.desc}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

Thankyou.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
        img: PropTypes.string.isRequired,
        desc: PropTypes.string.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    getData: PropTypes.func.isRequired,
};

Thankyou.defaultProps = {
    location: PropTypes.shape({
        pathname: '/',
    }),
};

export default withRouter(withStyles(styles)(Thankyou));