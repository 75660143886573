import {
    ADD_GET_IN_TOUCH,
    RENDER_GET_IN_TOUCH,
    UPDATE_GET_IN_TOUCH,
    ERROR_GET_IN_TOUCH,
    CLOSE_GET_IN_TOUCH,
} from 'actions/register';

const initialState = {
    loading: false,
    submitDone: false,
    data: {
        name: '',
        lname: '',
        mobile: '',
        email: '',
        budget: '',
        roomSize: '',
        campaignName: '',
        source: '',
        medium: '',
        term: '',
        content: '',
    },
    error: {
        name: '',
        lname: '',
        mobile: '',
        email: '',
        budget: '',
        roomSize: '',
    },
    isValid: false,
};

const registerReducer = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_GET_IN_TOUCH:
        return {
            ...state,
            data: {
                ...state.data,
                [action.fieldName]: action.newValue,
            },
        };
    case ERROR_GET_IN_TOUCH:
        return {
            ...state,
            error: action.error,
        };
    case ADD_GET_IN_TOUCH:
        return {
            ...state,
            loading: true,
        };
    case RENDER_GET_IN_TOUCH:
        return {
            loading: false,
            submitDone: true,
            data: {
                name: '',
                lname: '',
                mobile: '',
                email: '',
                budget: '',
                roomSize: '',
                campaignName: '',
                source: '',
                medium: '',
                term: '',
                content: '',
            },
            error: {
                name: '',
                lname: '',
                mobile: '',
                email: '',
                budget: '',
                roomSize: '',
            },
        };
    case CLOSE_GET_IN_TOUCH:
        return {
            ...state,
            submitDone: false,
        };
    default:
        return state;
    }
};
export default registerReducer;
