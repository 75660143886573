import moment from 'moment';
import firebase from 'common/firebase';
// import { showSnack } from './main';

export const ADD_GET_IN_TOUCH = 'ADD_GET_IN_TOUCH';
export const UPDATE_GET_IN_TOUCH = 'UPDATE_GET_IN_TOUCH';
export const ERROR_GET_IN_TOUCH = 'ERROR_GET_IN_TOUCH';
export const CLOSE_GET_IN_TOUCH = 'CLOSE_GET_IN_TOUCH';
export const RENDER_GET_IN_TOUCH = 'RENDER_GET_IN_TOUCH';
export function updateGetInTouch(fieldName, newValue) {
    let text = newValue;
    if (fieldName === 'mobile') {
        text = text.substring(0, 10);
    }

    return {
        type: UPDATE_GET_IN_TOUCH,
        fieldName,
        newValue: text,
    };
}
export function errorGetInTouch(error) {
    return {
        type: ERROR_GET_IN_TOUCH,
        error,
    };
}
export function addGetInTouch() {
    return {
        type: ADD_GET_IN_TOUCH,
    };
}
export function renderGetInTouch() {
    return {
        type: RENDER_GET_IN_TOUCH,
    };
}
export const closeGetInTouch = () => ({
    type: CLOSE_GET_IN_TOUCH,
});

export function postCreate(data) {
    return dispatch => {
        dispatch(addGetInTouch());

        const ref = firebase.firestore().collection('registers');
        const inputData = {
            ...data,
            timestamp: moment().valueOf(),
        };

        return ref
            .add(inputData)
            .then(() => {
                dispatch(renderGetInTouch());
                // dispatch(showSnack('success', 'Tag created successfully.'));
            })
            .catch(error => {
                console.log(error);
            });
    };
}
